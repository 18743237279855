import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UrlBarSyncService } from './urlBarSync.service';
import { GlobalsService } from './globals.service';
import { GlobalsChanges } from '../types/globals.type';
import { Subject } from 'rxjs';

@Injectable()
export class NavigationService {
  public navigationObserver = new Subject<Boolean>();

  constructor(
    private Router: Router,
    private UrlBarSyncService: UrlBarSyncService,
    private GlobalsService: GlobalsService,
  ) {}

  // pass the changes to the current navigation state
  // globals will be adapted in accordance
  public navigation(changes: GlobalsChanges): void {
    this.GlobalsService.setGlobals(changes);
    // notify navigation subscribers - this make components re-render if needed
    this.navigationObserver.next(true);
    // change internal route outlet if needed
    if (this.GlobalsService.getGlobal('dsUID')) {
      this.Router.navigate([{ outlets: { DSBWidgetElement: 'ds' } }]);
    } else if (this.GlobalsService.getGlobal('listUID')) {
      this.Router.navigate([{ outlets: { DSBWidgetElement: 'list' } }]);
    }
  }
}
