<div class="ds-path-wrapper">
  <ds-path-bar></ds-path-bar>
</div>
<div class="icon-legend-wrapper">
  <icon-legend></icon-legend>
</div>
<div class="table-wrapper">
  <native-table *ngIf="showTable && showClass"></native-table>
  <native-enumeration-table *ngIf="showTable && showEnumeration"></native-enumeration-table>
</div>
