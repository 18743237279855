import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { TreeLocationCoordinates } from '../tree-provider/types/tree-location-coordinates.type';
import { TreeProvider } from '../tree-provider/tree-provider.class';
import { TreeNode } from '../tree-provider/types/tree-node.type';
import { Subscription } from 'rxjs';
import { TreeControlService } from '../tree-control/treeControl.service';
import { UtilitiesService } from '../../../../shared/services/utilities.service';

@Component({
  selector: 'tr[table-row]',
  templateUrl: './table-row.component.html',
  styleUrls: ['./table-row.component.scss', './../tree-widget.component.scss'],
})
export class TableRowComponent implements OnInit, OnDestroy {
  @Input() tlc: TreeLocationCoordinates;
  @Input() treeProvider: TreeProvider;
  treeNode: TreeNode;
  @HostBinding('class') classArray: string[] = ['hover-copy'];
  @HostBinding('class.collapsed') isCollapsed: boolean = false;
  @HostBinding('class.highlight') isHighlighted: boolean = false;
  treeControlSubject: Subscription;

  constructor(
    private TreeControlService: TreeControlService,
    private UtilitiesService: UtilitiesService,
    public ElementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    this.treeNode = this.treeProvider.getNodeAtTLC(this.tlc);
    // add the border color as background if table row is a datatype or a merged property
    if (this.treeNode.nodeType === 'Property') {
      this.classArray.push('dark-bg');
    }
    // register this element ref in the TreeNode
    this.treeNode.tableRow = this;
    // set up the tree control subscription
    this.treeControlSubject = this.TreeControlService.getSubject().subscribe(() => {
      this.isCollapsed = !this.treeNode.isShown;
    });
  }

  ngOnDestroy(): void {
    this.UtilitiesService.unSubIfExists([this.treeControlSubject]);
  }
}
