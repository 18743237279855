import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { GlobalsService } from '../../shared/services/globals.service';
import { UtilitiesService } from '../../shared/services/utilities.service';
import { Subscription } from 'rxjs';
import { DsUtilitiesService } from '../../shared/services/dsUtilities.service';
import { DsUtilitiesV7 } from 'ds-utilities';
import { DsV7 } from 'ds-utilities';
import { UrlBarSyncService } from '../../shared/services/urlBarSync.service';
import { NavigationService } from '../../shared/services/navigation.service';

@Component({
  selector: 'native-view',
  templateUrl: './native-view.component.html',
  styleUrls: ['./native-view.component.scss'],
})
export class NativeViewComponent implements OnInit, OnDestroy {
  ds: DsV7;
  dsUtil: DsUtilitiesV7;
  showTable = true; // switch to show one of the two Table when ready
  showClass = false; // switch to show the Class Table
  showEnumeration = false; // switch show the Enumeration Table
  pathDs: string;
  navigationSub: Subscription;
  globalsSub: Subscription;

  constructor(
    private Router: Router,
    private GlobalsService: GlobalsService,
    private UtilitiesService: UtilitiesService,
    private DsUtilitiesService: DsUtilitiesService,
    private ChangeDetectorRef: ChangeDetectorRef,
    private UrlBarSyncService: UrlBarSyncService,
    private NavigationService: NavigationService,
  ) {}

  ngOnInit(): void {
    this.dsUtil = this.DsUtilitiesService.getDsUtilities();
    this.UtilitiesService.initializeComponentWithSub(this, 'ds');
  }

  ngOnDestroy() {
    this.UtilitiesService.unSubIfExists([this.navigationSub, this.globalsSub]);
  }

  render(): void {
    if (!this.ds) {
      return;
    }
    this.handleDsPath();
    this.showTable = true;
    // create a subscription for when the pathDs changes - change elements rendered in the native view
    this.navigationSub = this.NavigationService.navigationObserver.subscribe(() => {
      const newPathDs = this.GlobalsService.getGlobal('pathDs');
      this.ds = this.GlobalsService.getGlobal('ds');
      if (this.ds && this.pathDs !== newPathDs) {
        // if the selected path has changed, then the current DS node displayed must be updated
        // instead of changing details of the rendered view, those views are destroyed and created again (bounce/blink?)
        this.handleDsPath();
        this.showTable = false;
        this.ChangeDetectorRef.detectChanges(); // this helps to have a nicer transition (no flickering)
        this.showTable = true;
      }
    });
  }

  // load the current DS path from the globals/url, initialize it if needed
  handleDsPath(): void {
    this.pathDs = this.GlobalsService.getGlobal('pathDs');
    if (this.pathDs === undefined) {
      // initialize pathDs
      this.UrlBarSyncService.initializePathDs();
      this.pathDs = this.GlobalsService.getGlobal('pathDs');
    }
    this.checkCurrentNodeType();
  }

  // get the current DS node and identify which table should be shown (Class vs. Enumeration)
  checkCurrentNodeType(): void {
    const newNode = this.DsUtilitiesService.getDsNodeAtPath(this.ds, this.pathDs, true);
    const nodeType = this.dsUtil.dsPathIdentifyNodeType(newNode, this.ds);
    this.showEnumeration = nodeType === 'Enumeration';
    this.showClass = !this.showEnumeration;
  }
}
