import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { MaterialIconStyle } from './types/material-icon-style.type';

@Component({
  selector: 'tree-icon',
  templateUrl: './tree-icon.component.html',
  styleUrls: ['./tree-icon.component.scss'],
})
export class TreeIconComponent implements OnInit {
  @Input() icon: string;
  @Input() style?: MaterialIconStyle;
  @Input() clickable?: boolean;

  classArray: string[] = ['grid-icon', 'color-primary'];

  constructor() {}

  ngOnInit(): void {
    // set style
    if (this.style) {
      this.classArray.push(this.style);
    } else {
      // default style
      this.classArray.push('material-icons');
    }
    if (this.clickable) {
      this.classArray.push('grid-button');
    }
  }
}
