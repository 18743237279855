import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TreeNode } from '../../../tree-provider/types/tree-node.type';
import { TreeLocationCoordinates } from '../../../tree-provider/types/tree-location-coordinates.type';
import { TreeProvider } from '../../../tree-provider/tree-provider.class';
import { TreeTileConfig } from './types/tree-tile-config.type';
import { Subscription } from 'rxjs';
import { TreeControlService } from '../../../tree-control/treeControl.service';
import { UtilitiesService } from '../../../../../../shared/services/utilities.service';

@Component({
  selector: 'tree-tile',
  templateUrl: './tree-tile.component.html',
  styleUrls: ['./tree-tile.component.scss'],
})
export class TreeTileComponent implements OnInit, OnDestroy {
  @Input() config: TreeTileConfig;
  @Input() treeProvider?: TreeProvider;
  @Input() tlc?: TreeLocationCoordinates;
  treeNode: TreeNode;

  public style: string;
  public iconClasses = {
    open: true,
  };

  private treeControlSubject: Subscription;

  constructor(private TreeControlService: TreeControlService, private UtilitiesService: UtilitiesService) {}

  ngOnInit(): void {
    if (this.config.pattern === 'blank') {
      // do nothing - tile is blank
      return;
    }
    if (this.config.hasToggler) {
      this.treeNode = this.treeProvider.getNodeAtTLC(this.tlc);
      // set up the tree control subscription
      this.treeControlSubject = this.TreeControlService.getSubject().subscribe(() => {
        this.iconClasses.open = this.treeNode.isOpened;
      });
    }
    const svgLines = this.getLines();
    this.style = `background-image: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 24'>${svgLines}</svg>")`;
  }

  ngOnDestroy(): void {
    this.UtilitiesService.unSubIfExists([this.treeControlSubject]);
  }

  // generate svg code for lines needed (for the needed tile type)
  getLines(): string {
    const lineStyle = this.getLineStyle();
    const coordsTopMid = "x1='10' y1='0' x2='10' y2='12'";
    const coordsMidRight = "x1='10' y1='12' x2='20' y2='12'";
    const coordsTopDown = "x1='10' y1='0' x2='10' y2='24'";
    const coordsLeftRight = "x1='2' y1='13' x2='20' y2='13'";

    if (this.config.pattern === 'top-right') {
      return `<line ${coordsTopMid} ${lineStyle}/><line ${coordsMidRight} ${lineStyle}/>`;
    } else if (this.config.pattern === 'top-down') {
      return `<line ${coordsTopDown} ${lineStyle}/>`;
    } else if (this.config.pattern === 'top-right-down') {
      return `<line ${coordsTopDown} ${lineStyle}/><line ${coordsMidRight} ${lineStyle}/>`;
    } else {
      // should never come here
      return '';
    }
  }

  // returns the svg line style needed
  getLineStyle(): string {
    if (this.config.lineType === 'dashed') {
      return "style='stroke:black; stroke-width:1; stroke-dasharray:1;' shape-rendering='crispEdges'";
    } else {
      // it is either "solid" or undefined, which we default to solid
      return "style='stroke:%23b7b7b7; stroke-width:1; stroke-dasharray:0;' shape-rendering='crispEdges'";
    }
  }

  // function triggered when clicking the toggler (if present)
  toggleTreeWidgetElement(): void {
    // toggle open status
    this.iconClasses.open = !this.iconClasses.open;
    // notify TreeControlService
    if (this.iconClasses.open) {
      this.TreeControlService.openNode(this.tlc, this.treeProvider);
    } else {
      this.TreeControlService.closeNode(this.tlc, this.treeProvider);
    }
  }
}
