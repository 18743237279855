<div class="dialog-title-container">
  <button
    class="dialog-close-button"
    mat-icon-button="mat-icon-button"
    (click)="close()"
    [title]="'shared.close' | translate"
  >
    <mat-icon>close</mat-icon>
  </button>
  <h1 mat-dialog-title="mat-dialog-title">{{ className }}</h1>
  <p class="mat-dialog-title-sub">{{ termType }}</p>
</div>
<div mat-dialog-content="mat-dialog-content">
  <table class="details-table">
    <tr>
      <th [title]="t('iri-hover') | translate">{{ t('iri') | translate }}:</th>
      <td>
        <ul>
          <li *ngFor="let u of uris">
            <span>{{ u.label }}</span
            ><a class="margin-left-5" target="_blank" [href]="u.uriLink" [title]="t('hover-iri-icon') | translate">
              <button class="inline-icon-button" mat-icon-button="mat-icon-button" color="primary">
                <mat-icon>launch</mat-icon>
              </button></a
            >
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <th [title]="t('labels-hover') | translate">{{ t('labels') | translate }}:</th>
      <td>
        <ul *ngIf="labels.length > 0">
          <li *ngFor="let l of labels">
            <span class="property-rdfs-lang">{{ l['@language'] }}:</span
            ><span class="property-rdfs-value margin-left-5">{{ l['@value'] }}</span>
          </li>
        </ul>
        <span *ngIf="labels.length === 0">-</span>
      </td>
    </tr>
    <tr>
      <th [title]="t('comments-hover') | translate">{{ t('comments') | translate }}:</th>
      <td>
        <ul *ngIf="comments.length > 0">
          <li *ngFor="let c of comments">
            <span class="property-rdfs-lang">{{ c['@language'] }}:</span
            ><span class="property-rdfs-value margin-left-5">{{ c['@value'] }}</span>
          </li>
        </ul>
        <span *ngIf="comments.length === 0">-</span>
      </td>
    </tr>
    <tr *ngIf="closed !== undefined">
      <th [title]="t('closed-hover') | translate">{{ t('closed') | translate }}:</th>
      <td>{{ closed }}</td>
    </tr>
  </table>
</div>
<div mat-dialog-actions="mat-dialog-actions">
  <button class="mat-button onl-button" (click)="close()" mat-flat-button="mat-flat-button" color="primary">
    {{ 'shared.close' | translate }}
  </button>
</div>
