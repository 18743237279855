/**
 * Checks if the given input is undefined or null
 *
 * @param {any} value - the input element to check
 * @returns {boolean} true if the given input is undefined or null
 */
export function isNil(value: unknown): value is null | undefined {
  return value === undefined || value === null;
}

/**
 * Checks if the given input is a string
 *
 * @param {any} value - the input element to check
 * @returns {boolean} true if the given input is a string
 */
export function isString(value: unknown): value is string {
  if (isNil(value)) {
    return false;
  }
  return typeof value === 'string' || value instanceof String;
}

/**
 * Checks if the given input is a JS object
 *
 * @param value - the input element to check
 * @returns true if the given input is a JS object
 */
export function isObject(value: unknown): value is Record<string, unknown> {
  if (Array.isArray(value)) {
    return false;
  }
  if (isNil(value)) {
    return false;
  }
  return typeof value === 'object';
}
