<!--tree tiles content-->
<tree-tile
  *ngFor="let ttc of treeTileConfigs; index as i"
  [config]="ttc"
  [treeProvider]="treeProvider"
  [tlc]="tlc"
></tree-tile>
<!--start node icon (icon for entities at the first level of the @graph)--><span
  class="margin-left-5"
  *ngIf="!startNodeType"
>
  <!-- root node--></span
>
<tree-icon *ngIf="startNodeType === 'root'" icon="description" [title]="t('hover-start-node-root') | translate">
  <!-- internal node-->
</tree-icon>
<tree-icon *ngIf="startNodeType === 'internal'" icon="file_open" [title]="t('hover-start-node-internal') | translate">
  <!-- external node--> </tree-icon
><a
  *ngIf="startNodeType === 'external'"
  target="_blank"
  [href]="startNodeLink"
  [title]="startNodeLink + ' ' + (t('hover-start-node-external') | translate) + ('shared.hover-click-open' | translate)"
>
  <tree-icon icon="note_add" [clickable]="true"></tree-icon>
  <!-- external internal node--></a
><a
  *ngIf="startNodeType === 'externalInternal'"
  target="_blank"
  [href]="startNodeLink"
  [title]="
    startNodeLink +
    ' ' +
    (t('hover-start-node-external-internal') | translate) +
    ('shared.hover-click-open' | translate)
  "
>
  <tree-icon icon="note_add" [clickable]="true"></tree-icon>
</a>
<!-- label// label if no ref link
--><span *ngIf="!referenceIcon" [ngClass]="labelClass"
  >{{ label }}
  <!-- label if ref link--></span
>
<ng-container *ngIf="referenceIcon"
  ><a (click)="scrollToReference()" [title]="(referenceTitle | translate) + (t('hover-click-scroll') | translate)">
    <tree-icon [icon]="referenceIcon" [clickable]="true"></tree-icon> </a
  ><a
    class="reference-link"
    (click)="scrollToReference()"
    [title]="(referenceTitle | translate) + (t('hover-click-scroll') | translate)"
    ><span [ngClass]="labelClass">{{ label }}</span></a
  ></ng-container
>
<!-- open in native view button--><span class="margin-left-10" *ngIf="hasNativeViewLink"></span
><a
  class="copy-icon"
  *ngIf="hasNativeViewLink"
  (click)="openInNativeView()"
  [title]="t('click-open-native') | translate"
>
  <tree-icon icon="open_in_new" [style]="'material-icons-outlined'" [clickable]="true"></tree-icon>
</a>
