import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SdoAdapterService } from '../../services/sdoAdapter.service';
import { EnumerationMemberNodeV7, LanguageTaggedString } from 'ds-utilities';
import { DialogTermData } from '../../types/dialog-term-data.type';

@Component({
  selector: 'dialog-enumeration-member',
  templateUrl: './dialog-enumeration-member.component.html',
  styleUrls: ['./dialog-enumeration-member.component.scss', './../../styles/term-dialog.scss'],
})
export class DialogEnumerationMemberComponent implements OnInit {
  public memName: string;
  public uri: string;
  public uriLink: string;
  public labels: LanguageTaggedString[];
  public comments: LanguageTaggedString[];

  constructor(
    private SdoAdapterService: SdoAdapterService,
    public dialogRef: MatDialogRef<DialogEnumerationMemberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogTermData,
  ) {}

  ngOnInit(): void {
    const dsUtil = this.data.dsUtilities;
    const sdoAdapter = this.data.sdoAdapter;
    const dsNode = this.data.dsNode as EnumerationMemberNodeV7;

    this.memName = dsUtil.prettyPrintCompactedIRIs(dsNode['@id']);
    this.uri = dsNode['@id'];
    this.uriLink = this.SdoAdapterService.getTermReferenceUrl(dsNode['@id'], sdoAdapter);

    this.labels = dsNode['rdfs:label'] || [];
    this.comments = dsNode['rdfs:comment'] || [];
  }

  close(): void {
    this.dialogRef.close();
  }

  public t(str: string): string {
    return 'dialog-enumeration-member.' + str;
  }
}
