import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { TreeLocationCoordinates } from '../../tree-provider/types/tree-location-coordinates.type';
import { TreeProvider } from '../../tree-provider/tree-provider.class';
import { TreeNode } from '../../tree-provider/types/tree-node.type';
import { SDOAdapter } from 'schema-org-adapter';
import { UtilitiesService } from '../../../../../shared/services/utilities.service';
import { GlobalsService } from '../../../../../shared/services/globals.service';

@Component({
  selector: 'td[table-cell-description]',
  templateUrl: './table-cell-description.component.html',
  styleUrls: ['./table-cell-description.component.scss'],
})
export class TableCellDescriptionComponent implements OnInit {
  @Input() tlc: TreeLocationCoordinates;
  @Input() treeProvider: TreeProvider;
  @HostBinding('class') classArray: string[] = ['onl-body2'];
  @HostBinding('innerHtml') innerHtml: string = '';
  treeNode: TreeNode;
  sdoAdapter: SDOAdapter;

  constructor(private UtilitiesService: UtilitiesService, private GlobalsService: GlobalsService) {}

  ngOnInit(): void {
    this.treeNode = this.treeProvider.getNodeAtTLC(this.tlc);
    this.sdoAdapter = this.GlobalsService.getGlobal('sdoAdapter');
    this.generateHtmlDescription();
  }

  private generateHtmlDescription(): void {
    let descriptionText: string = '';
    if (this.treeNode.nodeType === 'Property') {
      descriptionText = this.sdoAdapter.getProperty(this.treeNode.dsNode['sh:path']).getDescription();
    } else if (
      this.treeNode.nodeType === 'RootNode' ||
      this.treeNode.nodeType === 'RestrictedClass' ||
      this.treeNode.nodeType === 'RestrictedEnumeration' ||
      this.treeNode.nodeType === 'StandardClass' ||
      this.treeNode.nodeType === 'StandardEnumeration'
    ) {
      descriptionText = this.sdoAdapter.getClass(this.treeNode.dsNode['sh:class'][0]).getDescription();
    }
    this.innerHtml = sanitizeDescription(this.UtilitiesService.repairLinksInHTMLCode(descriptionText || ''));
  }
}

function sanitizeDescription(text: string) {
  return text
    .replace(/<ul>/gi, ' ')
    .replace(/<\/ul>/gi, ' ')
    .replace(/<li>/gi, ' ')
    .replace(/<\/li>/gi, ' ')
    .replace(/<br>/gi, ' ')
    .replace(/<\/br>/gi, ' ')
    .replace(/<br\/>/gi, ' ')
    .replace(/<br \/>/gi, ' ')
    .replace(/\n/gi, ' ')
    .replace(/\s\s/gi, ' ');
}
