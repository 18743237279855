import { ClipboardModule } from '@angular/cdk/clipboard';
import { OverlayContainer } from '@angular/cdk/overlay';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DoBootstrap, Inject, Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule, ɵDomSharedStylesHost } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterTestingModule } from '@angular/router/testing';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppConfigModule, AppConfigService } from '@onlim/ngx-app-config';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { AppRoutingModule } from './app-routing.module';
import { AppConfiguration } from './app.configuration';
import { DsViewComponent } from './dsb-widget/ds-view/ds-view.component';
import { HeaderComponent } from './dsb-widget/ds-view/header/header.component';
import { ListButtonComponent } from './dsb-widget/ds-view/list-button/list-button.component';
import { DsPathBarComponent } from './dsb-widget/ds-view/native-view/ds-path-bar/ds-path-bar.component';
import { IconLegendComponent } from './dsb-widget/ds-view/native-view/icon-legend/icon-legend.component';
import { NativeEnumerationTableComponent } from './dsb-widget/ds-view/native-view/native-enumeration-table/native-enumeration-table.component';
import { NativeTableComponent } from './dsb-widget/ds-view/native-view/native-table/native-table.component';
import { NativeViewComponent } from './dsb-widget/ds-view/native-view/native-view.component';
import { ShaclViewComponent } from './dsb-widget/ds-view/shacl-view/shacl-view.component';
import { TreeViewComponent } from './dsb-widget/ds-view/tree-view/tree-view.component';
import { TableCellCardinalityComponent } from './dsb-widget/ds-view/tree-view/tree-widget/table-row/table-cell-cardinality/table-cell-cardinality.component';
import { TableCellDescriptionComponent } from './dsb-widget/ds-view/tree-view/tree-widget/table-row/table-cell-description/table-cell-description.component';
import { TableCellTreeComponent } from './dsb-widget/ds-view/tree-view/tree-widget/table-row/table-cell-tree/table-cell-tree.component';
import { TreeIconComponent } from './dsb-widget/ds-view/tree-view/tree-widget/table-row/table-cell-tree/tree-icon/tree-icon.component';
import { TreeTileComponent } from './dsb-widget/ds-view/tree-view/tree-widget/table-row/table-cell-tree/tree-tile/tree-tile.component';
import { TableRowComponent } from './dsb-widget/ds-view/tree-view/tree-widget/table-row/table-row.component';
import { TreeWidgetComponent } from './dsb-widget/ds-view/tree-view/tree-widget/tree-widget.component';
import { DSBWidgetComponent } from './dsb-widget/dsb-widget.component';
import { ListViewComponent } from './dsb-widget/list-view/list-view.component';
import { DialogClassComponent } from './dsb-widget/shared/dialogs/dialog-class/dialog-class.component';
import { DialogDatatypeComponent } from './dsb-widget/shared/dialogs/dialog-datatype/dialog-datatype.component';
import { DialogEnumerationMemberComponent } from './dsb-widget/shared/dialogs/dialog-enumeration-member/dialog-enumeration-member.component';
import { DialogPropertyComponent } from './dsb-widget/shared/dialogs/dialog-property/dialog-property.component';
import { OnlOverlayContainer } from './overlay/onl-overlay-container';
import { DOCUMENT } from '@angular/common';

// required for AOT compilation
export function HttpLoaderFactory(httpClient: HttpClient, AppConfigService: AppConfigService): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient, AppConfigService.getConfig<AppConfiguration>().host + '/assets/i18n/');
}

@NgModule({
  declarations: [
    DSBWidgetComponent,
    HeaderComponent,
    NativeViewComponent,
    TreeViewComponent,
    ShaclViewComponent,
    DsPathBarComponent,
    NativeTableComponent,
    TreeWidgetComponent,
    ListViewComponent,
    NativeEnumerationTableComponent,
    ListButtonComponent,
    DsViewComponent,
    IconLegendComponent,
    TableRowComponent,
    TableCellTreeComponent,
    TableCellCardinalityComponent,
    TableCellDescriptionComponent,
    TreeTileComponent,
    TreeIconComponent,
    DialogPropertyComponent,
    DialogDatatypeComponent,
    DialogClassComponent,
    DialogEnumerationMemberComponent,
  ],
  imports: [
    // Angular
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    // Config
    AppConfigModule.forRoot({
      path: '/dsb/config.json',
      scriptId: 'onlim-dsb',
    }),
    // Routing
    AppRoutingModule,
    // I18n
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, AppConfigService],
      },
    }),
    // Material
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatSliderModule,
    MatToolbarModule,
    MatCardModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatDividerModule,
    MatTabsModule,
    MatDialogModule,
    // CDK
    ClipboardModule,
    // Other
    NgxJsonViewerModule,
  ],
  entryComponents: [DSBWidgetComponent],
  providers: [
    {
      provide: OverlayContainer,
      useClass: OnlOverlayContainer,
    },
  ],
  schemas: [],
  exports: [RouterTestingModule],
})
export class AppModule implements DoBootstrap {
  // https://indepth.dev/posts/1116/angular-web-components-a-complete-guide
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private injector: Injector,
    private domSharedStylesHost: ɵDomSharedStylesHost,
  ) {
    this.domSharedStylesHost.removeHost(this.document.head);
  }

  ngDoBootstrap(): void {
    const DSBWidgetConstructor = createCustomElement(DSBWidgetComponent, {
      injector: this.injector,
    });
    customElements.get('dsb-widget') || customElements.define('dsb-widget', DSBWidgetConstructor);
  }
}
