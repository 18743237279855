import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalsService } from '../../shared/services/globals.service';
import { DsV7 } from 'ds-utilities';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UtilitiesService } from '../../shared/services/utilities.service';

@Component({
  selector: 'shacl-view',
  templateUrl: './shacl-view.component.html',
  styleUrls: ['./shacl-view.component.scss'],
})
export class ShaclViewComponent implements OnInit, OnDestroy {
  public ds: DsV7;
  public showJson = false; // switch for showing loading animation vs. ngx-json-viewer
  private globalsSub: Subscription;

  constructor(
    private snackbar: MatSnackBar,
    private GlobalsService: GlobalsService,
    private TranslateService: TranslateService,
    private UtilitiesService: UtilitiesService,
  ) {}

  ngOnInit(): void {
    this.UtilitiesService.initializeComponentWithSub(this, 'ds');
  }

  ngOnDestroy(): void {
    this.UtilitiesService.unSubIfExists([this.globalsSub]);
  }

  render(): void {
    if (!this.ds) {
      return;
    }
    setTimeout(() => {
      this.showJson = true; // give the ngx-json-viewer some time to pre-render
    }, 750);
  }

  onCopy(): void {
    // get snackbar notification text from translate service
    this.TranslateService.get('shacl-view.copy-notification').subscribe((res: string) => {
      this.snackbar.open(res, null, {
        duration: 3000,
      });
    });
  }

  public t(str: string): string {
    return 'shacl-view.' + str;
  }
}
