import { DeployEnvironment } from '../types/deploy-environment.type';

export const environmentEndpoints: Record<DeployEnvironment, string> = {
  development: 'http://localhost:3000/',
  test: 'http://localhost:3000/',
  playground1: 'https://app-playground.onlim.com/api/dvs/',
  playground2: 'https://app-playground2.onlim.com/api/dvs/',
  playground3: 'https://app-playground3.onlim.com/api/dvs/',
  playground4: 'https://app-playground4.onlim.com/api/dvs/',
  staging: 'https://app-staging.onlim.com/api/dvs/',
  'dzt-staging': 'https://app-dzt-staging.onlim.com/api/dvs/',
  'pre-production': 'https://app-pre-production.onlim.com/api/dvs/',
  'dzt-pre-production': 'https://app-dzt-pre-production.onlim.com/api/dvs/',
  production: 'https://app.onlim.com/api/dvs/',
  'dzt-production': 'https://app.opendatagermany.io/api/dvs/',
  premium: 'https://semantify.it/', // we want to hide the fact that premium is used, only raw routes are used in the case of premium, so it is fine to use the redirected raw routes - 'https://app-premium.onlim.com/api/dvs/',
  semantify: 'https://semantify.it/',
} as const;
