<div class="text-center" *ngIf="dataSource?.filteredData.length === 0">
  <div class="loading-wrapper">
    <mat-spinner></mat-spinner>
  </div>
</div>
<div *ngIf="dataSource?.filteredData.length > 0">
  <table class="mat-elevation-z8 bordered" mat-table="mat-table" [dataSource]="dataSource">
    <ng-container matColumnDef="enumerationMember">
      <th class="enum-member" mat-header-cell *matHeaderCellDef><span [translate]="t('table-header-enum')"></span></th>
      <td class="enum-member hover-copy" mat-cell="mat-cell" *matCellDef="let element">
        <p class="margin-0 text-max-length-container">
          <b class="text-max-length" [title]="element.enumMemberLabel">{{ element.enumMemberLabel }}</b
          ><span class="margin-left-5"></span
          ><span class="copy-icon" *ngIf="element.enumMemberURL"
            ><a target="_blank" (click)="openEnumMemberDialog(element.id)" [title]="t('hover-enum') | translate">
              <button class="inline-icon-button" mat-icon-button="mat-icon-button" color="primary">
                <mat-icon *ngIf="element.hasAdvancedConstraints">info</mat-icon>
                <mat-icon class="material-icons-outlined" *ngIf="!element.hasAdvancedConstraints">info</mat-icon>
              </button></a
            ></span
          >
        </p>
      </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th class="description" mat-header-cell *matHeaderCellDef><span translate="shared.description"></span></th>
      <td class="description onl-body2" mat-cell="mat-cell" *matCellDef="let element">
        <p class="text-justify" *ngIf="element.descriptionFromVocab">
          <span [innerHTML]="element.descriptionFromVocab"></span>
        </p>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
