<list-button></list-button>
<div class="content-wrapper">
  <div class="mat-card" id="loading" *ngIf="loading">
    <div class="loading-wrapper">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <div class="mat-card text-center" id="no-content" *ngIf="!loading && showError">
    <mat-icon id="not-found">report</mat-icon>
    <p class="text-center">
      <ng-container *ngIf="errNoContent"
      ><span [translate]="t('no-content')"></span><b>{{ dsId }}</b
      ><span>!</span>
      </ng-container>
      <ng-container *ngIf="!errNoContent">
        <span>There was an error with the retrieved Domain Specification </span><b>{{ dsId }}:</b>
        <pre style="text-align: left; margin-top: 20px">{{ errorMessage }}</pre>
      </ng-container>
    </p>
  </div>
  <div class="mat-card" id="ds-content" *ngIf="!loading && !showError">
    <dsb-header></dsb-header>
    <mat-divider></mat-divider>
    <mat-tab-group dynamicHeight [(selectedIndex)]="tabIndex" (selectedTabChange)="onTabClick($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="material-icons-outlined">table_rows</mat-icon>
          <span class="margin-left-5" [translate]="t('tab-header-native')"></span>
        </ng-template>
        <native-view></native-view>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="material-icons-outlined">integration_instructions</mat-icon>
          <span class="margin-left-5" [translate]="t('tab-header-shacl')"></span>
        </ng-template>
        <shacl-view></shacl-view>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="material-icons-outlined">account_tree</mat-icon>
          <span class="margin-left-5" [translate]="t('tab-header-tree')"></span>
        </ng-template>
        <tree-view></tree-view>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
