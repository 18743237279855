import { Component, OnDestroy, OnInit } from '@angular/core';
import { DsUtilitiesService } from '../../shared/services/dsUtilities.service';
import { DsUtilitiesV7 } from 'ds-utilities';
import { DsV7 } from 'ds-utilities';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RetrievalService } from '../../shared/services/retrieval.service';
import { GlobalsService } from '../../shared/services/globals.service';
import { shareReplay } from 'rxjs/operators';
import { ExternalLink } from '../../shared/types/header.type';
import { UtilitiesService } from '../../shared/services/utilities.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, map, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'dsb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public dsName: string;
  public dsDescription: string;
  public detailsOpened = false; // switch for having the DS details section opened or not
  public dsIRI: string;
  public dsUtil: DsUtilitiesV7;
  public superDsIRI: string;
  public ds: DsV7;
  public schemaVersion: string;
  public dsVersion: string;
  public externalVocabularies: string[] = [];
  public externalReferences: string[] = [];
  public externalVocabularyEntries$: Observable<ExternalLink[]>;
  public externalReferenceEntries$: Observable<ExternalLink[]>;

  private globalsSub: Subscription;

  constructor(
    private snackbar: MatSnackBar,
    private DsUtilitiesService: DsUtilitiesService,
    private RetrievalService: RetrievalService,
    private GlobalsService: GlobalsService,
    private UtilitiesService: UtilitiesService,
    private TranslateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.UtilitiesService.initializeComponentWithSub(this, 'ds');
  }

  ngOnDestroy(): void {
    this.UtilitiesService.unSubIfExists([this.globalsSub]);
  }

  render(): void {
    if (!this.ds) {
      return;
    }
    this.dsUtil = this.DsUtilitiesService.getDsUtilities();
    this.dsName = this.dsUtil.getDsName(this.ds); // could add language here
    this.dsDescription = this.dsUtil.getDsDescription(this.ds); // could add language here
    this.dsIRI = this.dsUtil.getDsId(this.ds);
    this.schemaVersion = this.dsUtil.getDsSchemaVersion(this.ds);
    this.dsVersion = this.dsUtil.getDsVersion(this.ds);
    this.externalVocabularies = this.dsUtil.getDsExternalVocabularies(this.ds);
    this.superDsIRI = this.dsUtil.getDsRootNode(this.ds)['ds:subDSOf'];
    this.identifyExternalReferences();
    this.fetchExternalReferences();
    this.fetchExternalVocabularies();
  }

  // toggle function to show/hide the details about the current DS
  detailsToggle(): boolean {
    this.detailsOpened = !this.detailsOpened;
    return false;
  }

  // get information about the external vocabularies used by the current DS
  fetchExternalVocabularies(): void {
    this.externalVocabularyEntries$ = forkJoin(
      this.externalVocabularies.map((vocabIri) =>
        this.RetrievalService.getVocabName(this.UtilitiesService.extractUidFromIRI(vocabIri)).pipe(
          map((name) => ({
            label: name || vocabIri,
            iri: vocabIri,
          })),
        ),
      ),
    ).pipe(shareReplay());
  }

  // get information about the external references (DS) used by the current DS
  fetchExternalReferences(): void {
    this.externalReferenceEntries$ = forkJoin(
      this.externalReferences.map((refIri) =>
        this.RetrievalService.getDs(this.UtilitiesService.extractUidFromIRI(refIri), false).pipe(
          map((ds) => ({
            label: this.dsUtil.getDsName(ds),
            iri: refIri,
          })),
        ),
      ),
    ).pipe(shareReplay());
  }

  // snackbar notification for when a "copy"-button is used
  onCopy(elementName: string): void {
    // get snackbar notification text from translate service
    this.TranslateService.get('header.copy-notification-' + elementName).subscribe((res: string) => {
      this.snackbar.open(res, null, {
        duration: 3000,
      });
    });
  }

  // returns the @ids of external references used in the current DS
  identifyExternalReferences(): void {
    const rootNode = this.dsUtil.getDsRootNode(this.ds);
    this.externalReferences = this.ds['@graph']
      .filter((el) => !el['@id'].startsWith(rootNode['@id']) && !el['@id'].includes('#'))
      .map((node) => node['@id']);
  }

  public t(str: string): string {
    return 'header.' + str;
  }
}
