import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalsService } from './shared/services/globals.service';
import { UtilitiesService } from './shared/services/utilities.service';
import { DsUtilitiesService } from './shared/services/dsUtilities.service';
import { UrlBarSyncService } from './shared/services/urlBarSync.service';
import { NavigationService } from './shared/services/navigation.service';
import { RetrievalService } from './shared/services/retrieval.service';
import { SdoAdapterService } from './shared/services/sdoAdapter.service';
import { GlobalsChanges, RoutingStrategy, ViewMode, widgetParameters } from './shared/types/globals.type';
import { DialogService } from './shared/services/dialog.service';
import { DeployEnvironment } from './shared/types/deploy-environment.type';

@Component({
  selector: 'dsb-widget',
  templateUrl: './dsb-widget.component.html',
  styleUrls: ['./dsb-widget.component.scss', './shared/styles/global.scss'],
  providers: [
    GlobalsService,
    DsUtilitiesService,
    NavigationService,
    RetrievalService,
    SdoAdapterService,
    UrlBarSyncService,
    UtilitiesService,
    DialogService,
  ],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class DSBWidgetComponent implements OnInit, AfterViewInit, OnDestroy {
  // the following widget parameter are explained in readme.md
  @Input() public routingstrategy: RoutingStrategy; // undefined - no interaction with URL at all, "query" - query parameters are used, with a "dsb" namespace, "full" - full control of the URL (everything but domain) like in semantify.it
  @Input() public listiri: string; // although the whole IRI is given, a semantify IRI is expected
  @Input() public dsiri: string; // although the whole IRI is given, a semantify IRI is expected
  @Input() public viewmode: ViewMode; // undefined - native view (table), "tree" - tree view, "shacl" - shacl view
  @Input() public environment: DeployEnvironment = 'semantify';
  showWidget = true; // helps to blink the whole widget, e.g. when the DOM API is used to change the parameters and refresh the widget view

  private popStateEventListener = () => {
    const paramFromURL = this.UrlBarSyncService.readParametersFromURL(this.routingstrategy);
    const changes: GlobalsChanges = { ...paramFromURL };
    // also delete ds and pathDs if the dsUID had been removed (navigation to list view)
    if (paramFromURL.dsUID === undefined) {
      changes.ds = undefined;
      changes.pathDs = undefined;
    }
    // also actualize pathDs if the pathUrl may have changed
    if (paramFromURL.dsUID && this.GlobalsService.getGlobal('ds')) {
      changes.pathDs = this.UrlBarSyncService.decodePathFromURL(paramFromURL.pathUrl);
    }
    this.NavigationService.navigation(changes);
  };

  constructor(
    private Router: Router,
    private TranslateService: TranslateService,
    private GlobalsService: GlobalsService,
    private UtilitiesService: UtilitiesService,
    private DsUtilitiesService: DsUtilitiesService,
    private UrlBarSyncService: UrlBarSyncService,
    private NavigationService: NavigationService,
    private ElementRef: ElementRef,
    private zone: NgZone,
    private ChangeDetectorRef: ChangeDetectorRef,
    private RetrievalService: RetrievalService,
  ) {}

  // the widget must initialize: RoutingStrategy
  // the widget can initialize: Ds, List, view mode
  // the url can initialize: Ds, List, view mode, path
  ngOnInit(): void {
    this.RetrievalService.setEnvironment(this.environment);
    const paramFromWidget = this.readWidgetInput();
    this.TranslateService.use('en'); // could be a parameter for the widget too
    // routingstrategy is always taken from widget - can be undefined
    this.GlobalsService.setGlobal('routingStrategy', this.routingstrategy);
    if (this.routingstrategy) {
      // read parameters from URL - dsUID, listUID, viewMode, pathUrl
      const paramFromURL = this.UrlBarSyncService.readParametersFromURL(this.routingstrategy);
      this.NavigationService.navigation(paramFromURL);
      // add listener to browser back/forth navigation
      window.addEventListener('popstate', this.popStateEventListener);
    } else {
      // read parameters from Widget - dsUID, listUID, viewMode
      // path always starts undefined
      this.NavigationService.navigation(paramFromWidget);
    }
    // check if input is incorrect
    const globals = this.GlobalsService.getGlobals();
    if (globals.listUID === undefined && globals.dsUID === undefined) {
      if (globals.routingStrategy === undefined) {
        console.error(
          'DSB-Widget is being used incorrectly: If the standard routingstrategy is being used (that means no routingstrategy argument is passed), then listiri AND/OR dsiri must be provided.',
          '\n\n',
          "E.g.: <dsb-widget dsiri='https://semantify.it/ds/SJvwplpBF' ></dsb-widget>",
          '\n\n',
          'Please consult the readme of DSB-Widget.',
        );
      } else if (globals.routingStrategy === 'full') {
        console.error(
          'DSB-Widget is being used incorrectly: If the selected routingstrategy is "full", then the listiri AND/OR dsiri are being read from the URL and must follow a specific syntax.',
          '\n\n',
          'E.g.: the url <YOUR-DOMAIN>/ds/SJvwplpBF specifies the used DS for the DSB-Widget',
          '\n\n',
          'Please consult the readme of DSB-Widget.',
        );
      } else {
        console.error(
          'DSB-Widget is being used incorrectly: If the selected routingstrategy is "query", then the listiri AND/OR dsiri are being read from the URL and must follow a specific syntax.',
          '\n\n',
          'E.g.: the url <YOUR-DOMAIN>/<ANY-PATH>?dsb-ds=SJvwplpBF specifies the used DS for the DSB-Widget',
          '\n\n',
          'Please consult the readme of DSB-Widget.',
        );
      }
    }
  }

  ngAfterViewInit() {
    // create a DOM API function for the widget - navigate() takes the possible initial parameters of the widget as input, this allows the change the list/ds shown
    this.ElementRef.nativeElement.navigate = (inputNavObj: { dsiri: string; listiri: string; viewmode: ViewMode }) => {
      const inputProcessed: GlobalsChanges = {};
      if (inputNavObj.dsiri) {
        inputProcessed.dsUID = this.UtilitiesService.extractUidFromIRI(inputNavObj.dsiri);
        this.ElementRef.nativeElement.setAttribute('dsiri', inputNavObj.dsiri);
      } else {
        this.ElementRef.nativeElement.removeAttribute('dsiri');
      }
      if (inputNavObj.listiri) {
        inputProcessed.listUID = this.UtilitiesService.extractUidFromIRI(inputNavObj.listiri);
        this.ElementRef.nativeElement.setAttribute('listiri', inputNavObj.listiri);
      } else {
        this.ElementRef.nativeElement.removeAttribute('listiri');
      }
      if (inputNavObj.viewmode) {
        inputProcessed.viewMode = inputNavObj.viewmode;
        this.ElementRef.nativeElement.setAttribute('viewmode', inputNavObj.viewmode);
      } else {
        this.ElementRef.nativeElement.removeAttribute('viewmode');
      }
      const newNavigation: GlobalsChanges = {
        listUID: undefined,
        list: undefined,
        dsUID: undefined,
        ds: undefined,
        pathDs: undefined,
        pathUrl: undefined,
        viewMode: undefined,
        ...inputProcessed,
      };
      this.zone.run(() => {
        this.NavigationService.navigation(newNavigation);
        this.showWidget = false;
        this.ChangeDetectorRef.detectChanges(); // this helps to have a nicer transition (no flickering)
        this.showWidget = true;
      });
    };
  }

  ngOnDestroy(): void {
    // remove pop state event listener
    window.removeEventListener('popstate', this.popStateEventListener);
  }

  // read input parameters of the widget
  private readWidgetInput(): widgetParameters {
    return {
      dsUID: this.dsiri ? this.UtilitiesService.extractUidFromIRI(this.dsiri) : undefined,
      listUID: this.listiri ? this.UtilitiesService.extractUidFromIRI(this.listiri) : undefined,
      viewMode: this.viewmode,
    };
  }
}
