import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DsList } from '../types/dsList.type';
import { DsV7, LanguageTaggedString } from 'ds-utilities';
import { vocabPublicApiType } from '../types/header.type';
import { DeployEnvironment } from '../types/deploy-environment.type';
import { environmentEndpoints } from '../constants/environment-endpoints.data';

@Injectable()
export class RetrievalService {
  // a cache for fetched DS
  private dsCache: Record<
    string,
    {
      populated?: DsV7;
      notPopulated?: DsV7;
    }
  > = {};
  // a cache for fetched lists
  private listCache: Record<string, DsList> = {};
  private environment: DeployEnvironment;
  private apiPrefix: string;
  private premiumCheck = false; //this is true if the existence of the item on premium has already be done

  constructor(private HttpClient: HttpClient) {}

  public setEnvironment(environment: DeployEnvironment) {
    this.environment = environment;
    this.apiPrefix = environmentEndpoints[environment];
  }

  // resolves the (DS-V7) DS with the given uid, either from the internal cache or by calling the semantify API
  public getDs(uid: string, populated: boolean = true): Observable<DsV7> {
    if (populated && this.dsCache[uid]?.populated) {
      return of(this.dsCache[uid].populated);
    }
    if (!populated && this.dsCache[uid]?.notPopulated) {
      return of(this.dsCache[uid].notPopulated);
    }
    // else fetch and save in cache
    let targetUrl: string;
    // try if DS exists at premium - this should be a one-time-check . If there is a DS with this id at premium then change the environment to premium
    return this.checkPremiumExists(uid, 'ds').pipe(
      switchMap(() => {
        if (this.environment === 'semantify') {
          targetUrl = `${this.apiPrefix}api/v2/domainSpecifications/dsv7/${uid}`;
        } else {
          targetUrl = `${this.apiPrefix}ds/${uid}`;
        }

        return this.HttpClient.get<DsV7>(targetUrl, {
          params: {
            populate: populated,
          },
        }).pipe(
          tap((ds: DsV7): void => {
            if (!this.dsCache[uid]) {
              this.dsCache[uid] = {};
            }
            if (populated) {
              this.dsCache[uid].populated = ds;
            } else {
              this.dsCache[uid].notPopulated = ds;
            }
          }),
        );
      }),
    );
  }

  private checkPremiumExists(uid: string, entityType: 'ds' | 'list') {
    if (this.environment === 'semantify' && !this.premiumCheck) {
      return this.HttpClient.get(`${this.apiPrefix}${entityType}/${uid}`, {
        params: {
          'meta-wrapper': true,
        },
      }).pipe(
        tap(() => this.setEnvironment('premium')),
        catchError(() => {
          return of(undefined);
        }),
        tap(() => {
          this.premiumCheck = true;
        }),
      );
    }
    return of(undefined);
  }

  public getVocabName(vocId: string): Observable<string> {
    if (this.environment === 'semantify') {
      return this.HttpClient.get<vocabPublicApiType>(`${this.apiPrefix}api/v2/public/vocabularies/${vocId}`).pipe(
        map((x: vocabPublicApiType) => x.name),
      );
    } else {
      return this.HttpClient.get<{ 'schema:name': LanguageTaggedString[] }>(`${this.apiPrefix}voc/${vocId}`).pipe(
        map((x: { 'schema:name': LanguageTaggedString[] }) => x['schema:name']?.[0]?.['@value']),
      );
    }
  }

  // returns a DS List, either from the internal cache or by calling the semantify API
  public getList(listId: string): Observable<DsList> {
    // try cache
    if (this.listCache[listId]) {
      return of(this.listCache[listId]);
    }
    // else fetch and save in cache
    // try if DS exists at premium - this should be a one-time-check . If there is a List with this id at premium then change the environment to premium
    return this.checkPremiumExists(listId, 'list').pipe(
      switchMap(() => {
        return this.HttpClient.get<DsList>(`${this.apiPrefix}list/${listId}?representation=lean`).pipe(
          tap((dsList: DsList): void => {
            this.listCache[listId] = dsList;
          }),
        );
      }),
    );
  }
}
