import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SdoAdapterService } from '../../services/sdoAdapter.service';
import { LanguageTaggedString, RestrictedClassNodeV7, StandardClassNodeV7 } from 'ds-utilities';
import { DialogTermData } from '../../types/dialog-term-data.type';

@Component({
  selector: 'dialog-class',
  templateUrl: './dialog-class.component.html',
  styleUrls: ['./dialog-class.component.scss', './../../styles/term-dialog.scss'],
})
export class DialogClassComponent implements OnInit {
  public className: string;
  public uris: { label: string; uriLink: string }[] = [];
  public labels: LanguageTaggedString[];
  public comments: LanguageTaggedString[];
  public closed: boolean;
  public termType: string;

  constructor(
    private SdoAdapterService: SdoAdapterService,
    public dialogRef: MatDialogRef<DialogClassComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogTermData,
  ) {}

  ngOnInit(): void {
    const dsUtil = this.data.dsUtilities;
    const sdoAdapter = this.data.sdoAdapter;
    const dsNode = this.data.dsNode as StandardClassNodeV7; // take any type with sh:class for any class/enumeration type
    let nodeType: string = dsUtil.identifyDsGrammarNodeType(dsNode, this.data.ds, false, sdoAdapter);

    if (nodeType === 'RootNode' || nodeType === 'RestrictedClass' || nodeType === 'StandardClass') {
      this.termType = 'Class';
    } else {
      this.termType = 'Enumeration';
    }

    this.className = dsUtil.prettyPrintCompactedIRIs(dsNode['sh:class']);
    for (const c of dsNode['sh:class']) {
      this.uris.push({
        label: c,
        uriLink: this.SdoAdapterService.getTermReferenceUrl(c, sdoAdapter),
      });
    }

    this.labels = dsNode['rdfs:label'] || [];
    this.comments = dsNode['rdfs:comment'] || [];
    if (this.termType === 'Class') {
      const classNode = dsNode as RestrictedClassNodeV7;
      this.closed = classNode['sh:closed'] || true; // we assume "true" to be the default https://gitbook.semantify.it/domainspecifications/ds-v7/grammar/domainspecification/class#3.2.2.-sh-closed
    } else if (this.termType === 'Enumeration') {
      // const enumNode = dsNode as RestrictedEnumerationNodeV7; // could be used if enumerations had any special attribute
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  public t(str: string): string {
    return 'dialog-class.' + str;
  }
}
