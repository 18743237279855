import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { TreeLocationCoordinates } from '../../tree-provider/types/tree-location-coordinates.type';
import { TreeProvider } from '../../tree-provider/tree-provider.class';
import { TreeNode } from '../../tree-provider/types/tree-node.type';
import { UtilitiesService } from '../../../../../shared/services/utilities.service';

@Component({
  selector: 'td[table-cell-cardinality]',
  templateUrl: './table-cell-cardinality.component.html',
  styleUrls: ['./table-cell-cardinality.component.scss'],
})
export class TableCellCardinalityComponent implements OnInit {
  @Input() tlc: TreeLocationCoordinates;
  @Input() treeProvider: TreeProvider;
  treeNode: TreeNode;
  @HostBinding('class') classArray: string[] = ['text-center'];
  @HostBinding('title') title: string;
  text: string = '';

  constructor(private UtilitiesService: UtilitiesService) {}

  ngOnInit(): void {
    this.treeNode = this.treeProvider.getNodeAtTLC(this.tlc);
    if (this.treeNode.nodeType === 'Property') {
      const cardinalityTextObject = this.UtilitiesService.resolveCardinalityText(
        this.treeNode.dsNode['sh:minCount'],
        this.treeNode.dsNode['sh:maxCount'],
      );
      this.text = cardinalityTextObject.cardinalityText;
      this.title = cardinalityTextObject.cardinalityTitle;
    }
  }
}
