<div>
  <h2 class="headline hover-copy margin-bottom-5">
    {{ dsName
    }}<span class="margin-left-10">
      <button
        class="inline-icon-button-big"
        mat-icon-button="mat-icon-button"
        color="primary"
        [title]="t('hover-copy-ds') | translate"
        [cdkCopyToClipboard]="dsIRI"
        [cdkCopyToClipboardAttempts]="3"
        onclick="this.blur()"
        (click)="onCopy('ds')"
      >
        <mat-icon class="copy-icon">file_copy</mat-icon>
      </button></span
    >
  </h2>
  <p class="onl-body2 text-justify margin-0">{{ dsDescription }}</p>
  <div class="details-toggle margin-top-5">
    <a class="primary-link details-toggle" (click)="detailsToggle()"
      ><span *ngIf="!detailsOpened" [title]="t('hover-details-open') | translate">
        <mat-icon>arrow_drop_down</mat-icon><span [translate]="t('details-open')"></span></span
      ><span *ngIf="detailsOpened" [title]="t('hover-details-close') | translate">
        <mat-icon>arrow_drop_up</mat-icon><span [translate]="t('details-close')"></span></span
    ></a>
  </div>
  <div *ngIf="detailsOpened">
    <table class="details-table">
      <tr class="hover-copy">
        <th><span [translate]="t('ds-iri')"></span></th>
        <td>
          <a class="primary-link" [href]="dsIRI" target="_blank">{{ dsIRI }}</a
          ><span class="margin-left-10">
            <button
              class="inline-icon-button"
              mat-icon-button="mat-icon-button"
              color="primary"
              [title]="t('hover-copy-ds') | translate"
              [cdkCopyToClipboard]="dsIRI"
              [cdkCopyToClipboardAttempts]="3"
              onclick="this.blur()"
              (click)="onCopy('ds')"
            >
              <mat-icon class="copy-icon">file_copy</mat-icon>
            </button></span
          >
        </td>
      </tr>
      <tr class="hover-copy" *ngIf="superDsIRI">
        <th><span [translate]="t('super-ds-iri')"></span></th>
        <td>
          <a class="primary-link" [href]="superDsIRI" target="_blank">{{ superDsIRI }}</a
          ><span class="margin-left-10">
            <button
              class="inline-icon-button"
              mat-icon-button="mat-icon-button"
              color="primary"
              [title]="t('hover-copy-super-ds') | translate"
              [cdkCopyToClipboard]="superDsIRI"
              [cdkCopyToClipboardAttempts]="3"
              onclick="this.blur()"
              (click)="onCopy('super-ds')"
            >
              <mat-icon class="copy-icon">file_copy</mat-icon>
            </button></span
          >
        </td>
      </tr>
      <tr *ngIf="dsVersion" [title]="t('hover-ds-version') | translate">
        <th><span [translate]="t('ds-version')"></span></th>
        <td>
          <span>{{ dsVersion }}</span>
        </td>
      </tr>
      <tr *ngIf="schemaVersion" [title]="t('hover-schema-version') | translate">
        <th><span [translate]="t('schema-version')"></span></th>
        <td>
          <span>{{ schemaVersion }}</span>
        </td>
      </tr>
      <tr
        *ngIf="externalVocabularyEntries$ | async as externalVocabularyEntries"
        [title]="t('hover-list-ext-voc') | translate"
      >
        <th><span [translate]="t('ext-voc')"></span></th>
        <td>
          <ul>
            <li class="hover-copy" *ngFor="let v of externalVocabularyEntries">
              <a class="primary-link" [href]="v.iri" target="_blank">{{ v.label }}</a
              ><span class="margin-left-10">
                <button
                  class="inline-icon-button"
                  mat-icon-button="mat-icon-button"
                  color="primary"
                  [title]="t('hover-copy-ext-voc') | translate"
                  [cdkCopyToClipboard]="v.iri"
                  [cdkCopyToClipboardAttempts]="3"
                  onclick="this.blur()"
                  (click)="onCopy('ext-voc')"
                >
                  <mat-icon class="copy-icon">file_copy</mat-icon>
                </button></span
              >
            </li>
          </ul>
        </td>
      </tr>
      <tr
        *ngIf="externalReferenceEntries$ | async as externalReferenceEntries"
        [title]="t('hover-list-ext-ref') | translate"
      >
        <th><span [translate]="t('ext-ref')"></span></th>
        <td>
          <ul>
            <li class="hover-copy" *ngFor="let r of externalReferenceEntries">
              <a class="primary-link" [href]="r.iri" target="_blank">{{ r.label }}</a
              ><span class="margin-left-10">
                <button
                  class="inline-icon-button"
                  mat-icon-button="mat-icon-button"
                  color="primary"
                  [title]="t('hover-copy-ext-ref') | translate"
                  [cdkCopyToClipboard]="r.iri"
                  [cdkCopyToClipboardAttempts]="3"
                  onclick="this.blur()"
                  (click)="onCopy('ext-ref')"
                >
                  <mat-icon class="copy-icon">file_copy</mat-icon>
                </button></span
              >
            </li>
          </ul>
        </td>
      </tr>
    </table>
  </div>
</div>
