import { Injectable, ViewContainerRef } from '@angular/core';
import { DsUtilitiesV7, RestrictedEnumerationNodeV7 } from 'ds-utilities';
import { DsV7 } from 'ds-utilities';
import { DialogPropertyComponent } from '../dialogs/dialog-property/dialog-property.component';
import { MatDialog } from '@angular/material/dialog';
import { SDOAdapter } from 'schema-org-adapter';
import { DialogDatatypeComponent } from '../dialogs/dialog-datatype/dialog-datatype.component';
import { DialogClassComponent } from '../dialogs/dialog-class/dialog-class.component';
import { DialogEnumerationMemberComponent } from '../dialogs/dialog-enumeration-member/dialog-enumeration-member.component';

@Injectable()
export class DialogService {
  constructor(private MatDialog: MatDialog, private ViewContainerRef: ViewContainerRef) {}

  // opens a dialog showing the advanced constraints of a term (ds path given)
  public openTermDialog(ds: DsV7, dsPath: string, dsUtilities: DsUtilitiesV7, sdoAdapter: SDOAdapter): void {
    const dsNode = dsUtilities.dsPathGetNode(ds, dsPath, true);
    const nodeType = dsUtilities.identifyDsGrammarNodeType(dsNode, ds, false, sdoAdapter);
    let targetDialog: any;
    if (
      nodeType === 'RestrictedClass' ||
      nodeType === 'StandardClass' ||
      nodeType === 'RootNode' ||
      nodeType === 'StandardEnumeration' ||
      nodeType === 'RestrictedEnumeration'
    ) {
      targetDialog = DialogClassComponent;
    } else if (nodeType === 'Property') {
      targetDialog = DialogPropertyComponent;
    } else if (nodeType === 'DataType') {
      targetDialog = DialogDatatypeComponent;
    }
    this.MatDialog.open(targetDialog, {
      autoFocus: false,
      restoreFocus: false,
      viewContainerRef: this.ViewContainerRef,
      width: '80vw',
      data: {
        dsNode,
        ds,
        sdoAdapter,
        dsUtilities,
      },
    });
  }

  // opens a dialog for an enumeration member
  openEnumMemberDialog(
    ds: DsV7,
    dsPath: string,
    enumMemberId: string,
    dsUtilities: DsUtilitiesV7,
    sdoAdapter: SDOAdapter,
  ) {
    const enumerationNode = dsUtilities.dsPathGetNode(ds, dsPath, true) as RestrictedEnumerationNodeV7;
    const memberNode = enumerationNode['sh:in'].find((el) => el['@id'] === enumMemberId);
    this.MatDialog.open(DialogEnumerationMemberComponent, {
      autoFocus: false,
      restoreFocus: false,
      viewContainerRef: this.ViewContainerRef,
      width: '80vw',
      data: {
        dsNode: memberNode,
        ds,
        sdoAdapter,
        dsUtilities,
      },
    });
  }
}
