<div>
  <p class="reference" *ngIf="rootPointer">
    <span class="onl-body2" [translate]="t('reference-info')"></span
    ><span
      ><a class="primary-link" (click)="breadcrumbsNavigation(rootPointer)" [attr.href]="rootViewUrl">{{
        rootLabel
      }}</a></span
    >
  </p>
  <p class="path">
    <span class="onl-body2" [title]="t('path-hover') | translate" [translate]="t('path')"></span
    ><span class="margin-left-10">
      <ng-container *ngFor="let pi of pathItems; let i = index"
        ><span *ngIf="pi.grammarNodeType === 'Property'">{{ pi.label }}</span
        ><span *ngIf="pi.grammarNodeType !== 'Property' && i !== pathItems.length - 1">
          <a class="primary-link" (click)="breadcrumbsNavigation(pi.pathPointer)" [attr.href]="pi.pathViewUrl">{{
            pi.label
          }}</a></span
        ><span *ngIf="pi.grammarNodeType !== 'Property' && i === pathItems.length - 1">
          <a
            class="primary-link font-weight-bold"
            (click)="breadcrumbsNavigation(pi.pathPointer)"
            [attr.href]="pi.pathViewUrl"
            >{{ pi.label }}</a
          ><span class="margin-left-5"
            ><span
              *ngIf="pi.dsPathNodeType === 'InternalReference' || pi.dsPathNodeType === 'InternalExternalReference'"
            >
              <button
                class="inline-icon-button"
                mat-icon-button="mat-icon-button"
                [title]="pi.externalURL + ' ' + ('shared.hover-int-ref' | translate)"
                color="primary"
              >
                <mat-icon>file_open</mat-icon>
              </button></span
            ><span *ngIf="pi.dsPathNodeType === 'RootReference'">
              <button
                class="inline-icon-button"
                mat-icon-button="mat-icon-button"
                [title]="'shared.hover-root-ref' | translate"
                color="primary"
              >
                <mat-icon>restore_page</mat-icon>
              </button></span
            ><span *ngIf="pi.dsPathNodeType === 'ExternalReference'">
              <a
                target="_blank"
                [href]="pi.externalURL"
                [title]="
                  pi.externalURL + ' ' + ('shared.hover-ext-ref' | translate) + ('shared.hover-click-open' | translate)
                "
              >
                <button class="inline-icon-button" mat-icon-button="mat-icon-button" color="primary">
                  <mat-icon>note_add</mat-icon>
                </button></a
              ></span
            ></span
          ></span
        ><span *ngIf="i === pathItems.length - 1"
          ><a (click)="openTermDialog(pathDs)" [title]="t('hover-term-dialog') | translate">
            <button class="inline-icon-button" mat-icon-button="mat-icon-button" color="primary">
              <mat-icon *ngIf="hasAdvancedConstraints">info</mat-icon>
              <mat-icon class="material-icons-outlined" *ngIf="!hasAdvancedConstraints">info</mat-icon>
            </button></a
          ></span
        ><span class="onl-body2 breadcrumbs-divider" *ngIf="i + 1 < pathItems.length">></span></ng-container
      ></span
    >
  </p>
</div>
