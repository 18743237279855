import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TreeProvider } from './tree-provider/tree-provider.class';
import { DsV7 } from 'ds-utilities';
import { TreeProviderService } from './tree-provider/tree-provider.service';
import { TreeNode } from './tree-provider/types/tree-node.type';
import { TreeLocationCoordinates } from './tree-provider/types/tree-location-coordinates.type';
import { TreeControlService } from './tree-control/treeControl.service';

@Component({
  selector: 'tree-widget',
  templateUrl: './tree-widget.component.html',
  styleUrls: ['./tree-widget.component.scss'],
  providers: [TreeProviderService, TreeControlService],
  encapsulation: ViewEncapsulation.None,
})
export class TreeWidgetComponent implements OnInit {
  @Input() ds: DsV7;
  @ViewChild('table', { static: false }) tableElementRef: ElementRef;
  treeProvider: TreeProvider;
  rowTLCs: TreeLocationCoordinates[] = [];

  constructor(
    private sanitizer: DomSanitizer,
    private TreeProviderService: TreeProviderService,
    private TreeControlService: TreeControlService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (!this.ds) {
      return;
    }
    this.treeProvider = this.TreeProviderService.create(this.ds);
    await this.createRowTLCs();
    this.TreeControlService.registerTable(this.tableElementRef.nativeElement);
  }

  // creates an array of TLC based on the input tree, each TLC represents a row in the table
  private async createRowTLCs() {
    const inputTree = this.treeProvider.getTree();
    for (const rootNode of inputTree) {
      this.createRowTLCsRec(rootNode);
    }
  }

  private createRowTLCsRec(treeNode: TreeNode) {
    this.rowTLCs.push(treeNode.tlc);
    for (const childNode of treeNode.children) {
      this.createRowTLCsRec(childNode);
    }
  }

  // opens all togglers of the tree
  public async expandTree(): Promise<void> {
    await this.TreeControlService.expandTree(this.treeProvider);
  }

  // open/show elements until the first level of ranges
  public async collapseTree(): Promise<void> {
    await this.TreeControlService.collapseTree(this.treeProvider);
  }

  // translation function
  public t(str: string): string {
    return 'tree-widget.' + str;
  }
}
