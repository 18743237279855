// takes a DS, coverts it into a tree and a corresponding tlc map which is made available
import { DsNodeGeneric, DsUtilitiesV7, DsV7, PropertyNodeV7, PropertyRangeShNodeV7 } from 'ds-utilities';
import { SdoAdapterService } from '../../../../shared/services/sdoAdapter.service';
import { TreeNode } from './types/tree-node.type';
import { DsUtilitiesService } from '../../../../shared/services/dsUtilities.service';
import { createTLC, TreeLocationCoordinates } from './types/tree-location-coordinates.type';

export class TreeProvider {
  private tree: TreeNode[] = [];
  private dsUtil: DsUtilitiesV7;

  constructor(
    private ds: DsV7,
    private SdoAdapterService: SdoAdapterService,
    private DsUtilitiesService: DsUtilitiesService,
    private maxDepth: Number,
  ) {
    this.init();
  }

  private init(): void {
    this.dsUtil = this.DsUtilitiesService.getDsUtilities();
    for (let index = 1; index <= this.ds['@graph'].length; index++) {
      this.tree.push(this.createTreeNode(this.ds['@graph'][index - 1], createTLC(index.toString())));
    }
  }

  getTree() {
    return this.tree;
  }

  getNodeAtTLC(tlc: TreeLocationCoordinates): TreeNode {
    const tlcTokens = tlc.split('.');
    let actualPathTlc = '';
    let actualSearchSet = this.tree;
    let isValidTlc = true;
    let actualTreeNode;
    tlcTokens.forEach((t) => {
      if (!isValidTlc) {
        return; // this means that an invalid TLC has been given, don't further search down the tree
      }
      actualPathTlc += actualPathTlc === '' ? t : '.' + t;
      const match = actualSearchSet.find((el) => el.tlc === actualPathTlc);
      if (!match) {
        isValidTlc = false;
      } else {
        actualTreeNode = match;
        actualSearchSet = actualTreeNode.children;
      }
    });
    return isValidTlc ? actualTreeNode : undefined; // return undefined if an invalid TLC has been given
  }

  createTreeNode(dsNode: DsNodeGeneric, tlc: TreeLocationCoordinates): TreeNode {
    const newTreeNode: TreeNode = {
      tlc: tlc,
      nodeType: undefined,
      originNodeType: undefined,
      dsNode: dsNode,
      children: [],
      isOpened: true,
      isShown: true,
    };

    // identify node type
    newTreeNode.nodeType = this.dsUtil.identifyDsGrammarNodeType(dsNode, this.ds, true);
    newTreeNode.originNodeType = this.dsUtil.identifyDsGrammarNodeType(dsNode, this.ds, false);
    if (
      newTreeNode.originNodeType === 'ExternalReference' ||
      newTreeNode.originNodeType === 'InternalReference' ||
      newTreeNode.originNodeType === 'InternalExternalReference' ||
      newTreeNode.originNodeType === 'RootReference'
    ) {
      newTreeNode.dsNode = this.ds['@graph'].find((el) => el['@id'] === dsNode['@id']);
    } else if (newTreeNode.dsNode['sh:property'] && tlc.split('.').length < this.maxDepth) {
      // add property children only if maxDepth has not been reached yet AND if its not a reference
      let propIndex = 1;
      newTreeNode.dsNode['sh:property'].map((p: PropertyNodeV7) =>
        newTreeNode.children.push(this.createTreeNode(p, createTLC(tlc + '.' + propIndex++))),
      );
    }

    if (newTreeNode.dsNode['sh:or']) {
      let rangeIndex = 1;
      newTreeNode.dsNode['sh:or'].map((p: PropertyRangeShNodeV7) => {
        if (p['sh:node']) {
          const rangeType = this.dsUtil.identifyDsGrammarNodeType(p['sh:node'], this.ds, false);
          if (
            rangeType === 'RestrictedClass' ||
            rangeType === 'RestrictedEnumeration' ||
            rangeType === 'ExternalReference' ||
            rangeType === 'InternalReference' ||
            rangeType === 'InternalExternalReference' ||
            rangeType === 'RootReference'
          ) {
            newTreeNode.children.push(this.createTreeNode(p['sh:node'], createTLC(tlc + '.' + rangeIndex++)));
          } else {
            console.log(`RANGE TYPE "${rangeType}" not handled!`);
          }
        } else {
          newTreeNode.children.push(this.createTreeNode(p, createTLC(tlc + '.' + rangeIndex++)));
        }
      });
    }

    return newTreeNode;
  }
}
