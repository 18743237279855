import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalsService } from '../../../shared/services/globals.service';
import { DsUtilitiesService } from '../../../shared/services/dsUtilities.service';
import { DsV7 } from 'ds-utilities';
import { PathTokenObjectV7 } from 'ds-utilities';
import { NavigationService } from '../../../shared/services/navigation.service';
import { DsUtilitiesV7 } from 'ds-utilities';
import { UrlBarSyncService } from '../../../shared/services/urlBarSync.service';
import { Subscription } from 'rxjs';
import { UtilitiesService } from '../../../shared/services/utilities.service';
import { DialogService } from '../../../shared/services/dialog.service';

type pathItem = {
  label: string;
  pathPointer: string;
  grammarNodeType: string;
  dsPathNodeType: string;
  externalURL: string;
  pathViewUrl: string;
};

@Component({
  selector: 'ds-path-bar',
  templateUrl: './ds-path-bar.component.html',
  styleUrls: ['./ds-path-bar.component.scss'],
})
export class DsPathBarComponent implements OnInit, OnDestroy {
  ds: DsV7;
  dsUtil: DsUtilitiesV7;
  pathDs: string;
  hasAdvancedConstraints: boolean;
  pathItems: pathItem[]; // path items processed to render the ds-path-bar
  navigationSub: Subscription;
  globalsSub: Subscription;
  // in case the start node being showed is NOT the root node, then a reference information is shown
  rootPointer: string;
  rootViewUrl: string;
  rootLabel: string;

  constructor(
    private GlobalsService: GlobalsService,
    private DsUtilitiesService: DsUtilitiesService,
    private NavigationService: NavigationService,
    private UrlBarSyncService: UrlBarSyncService,
    private UtilitiesService: UtilitiesService,
    private DialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.dsUtil = this.DsUtilitiesService.getDsUtilities();
    // create a subscription for when the pathDs changes - change elements rendered in this view
    this.navigationSub = this.NavigationService.navigationObserver.subscribe(() => {
      const newPath = this.GlobalsService.getGlobal('pathDs');
      if (newPath && newPath !== this.pathDs) {
        this.render();
      }
    });
    this.UtilitiesService.initializeComponentWithSub(this, 'ds');
  }

  ngOnDestroy() {
    this.UtilitiesService.unSubIfExists([this.navigationSub, this.globalsSub]);
  }

  // update the pathItems, which render the breadcrumbs in the view
  render(): void {
    if (!this.ds) {
      return;
    }
    this.pathDs = this.GlobalsService.getGlobal('pathDs');
    this.hasAdvancedConstraints = this.UtilitiesService.hasAdvancedConstraints(
      this.dsUtil.dsPathGetNode(this.ds, this.pathDs, true),
      'Class',
    ); // assume it is a class
    const routingStrategy = this.GlobalsService.getGlobal('routingStrategy');
    // get the tokens for the actual dsPath with help of DS Utilities
    const pathTokens: PathTokenObjectV7[] = this.dsUtil.tokenizeDsPath(this.ds, this.pathDs);
    // show reference info if current start node is not the root node (e.g. reference definitions)
    if (pathTokens[0].currentPath !== '$') {
      const rootPathToken = this.dsUtil.tokenizeDsPath(this.ds, '$');
      this.rootPointer = rootPathToken[0].currentPath;
      this.rootLabel = rootPathToken[0].label;
      this.rootViewUrl = this.UrlBarSyncService.createNewUrl(routingStrategy, {
        dsUID: this.GlobalsService.getGlobal('dsUID'),
        listUID: this.GlobalsService.getGlobal('listUID'),
        pathUrl: this.UrlBarSyncService.encodePathToURL(rootPathToken[0].currentPath),
        viewMode: undefined,
      });
    } else {
      this.rootPointer = undefined;
      this.rootLabel = undefined;
      this.rootViewUrl = undefined;
    }
    // create the pathItems rendered in this view, based on the pathTokens resolved before
    this.pathItems = pathTokens.map((el) => {
      let externalURL: string;
      if (
        el.dsPathNodeType === 'InternalReference' ||
        el.dsPathNodeType === 'InternalExternalReference' ||
        el.dsPathNodeType === 'ExternalReference'
      ) {
        const graphNode = this.ds['@graph'].find((n) => n['@id'].endsWith(el.token.substring(2)));
        if (graphNode) {
          externalURL = graphNode['@id'];
        }
      }
      let pathViewUrl: string;
      if (routingStrategy) {
        pathViewUrl = this.UrlBarSyncService.createNewUrl(routingStrategy, {
          dsUID: this.GlobalsService.getGlobal('dsUID'),
          listUID: this.GlobalsService.getGlobal('listUID'),
          pathUrl: this.UrlBarSyncService.encodePathToURL(el.currentPath),
          viewMode: undefined,
        });
      }
      return {
        label: el.label,
        pathPointer: el.currentPath,
        grammarNodeType: el.grammarNodeType,
        dsPathNodeType: el.dsPathNodeType,
        externalURL,
        pathViewUrl,
      };
    });
  }

  // navigation function for when the user clicks on a breadcrumbs-link
  breadcrumbsNavigation(dsPath: string): boolean {
    if (dsPath !== this.pathDs) {
      this.NavigationService.navigation({ pathDs: dsPath });
      this.UrlBarSyncService.setUrlChanges('new');
    }
    return false;
  }

  // opens a dialog showing the advanced constraints of a property (ds path given)
  public openTermDialog(dsPath: string): void {
    this.DialogService.openTermDialog(this.ds, dsPath, this.dsUtil, this.GlobalsService.getGlobal('sdoAdapter'));
  }

  public t(str: string): string {
    return 'ds-path-bar.' + str;
  }
}
