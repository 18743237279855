import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { RouterTestingModule } from '@angular/router/testing';
import { DSBWidgetComponent } from './dsb-widget/dsb-widget.component';
import { ListViewComponent } from './dsb-widget/list-view/list-view.component';
import { DsViewComponent } from './dsb-widget/ds-view/ds-view.component';

const routes: Routes = [
  {
    path: '',
    component: DSBWidgetComponent,
    outlet: 'DSBWidgetElement',
  },
  {
    path: 'list',
    component: ListViewComponent,
    outlet: 'DSBWidgetElement',
  },
  {
    path: 'ds',
    component: DsViewComponent,
    outlet: 'DSBWidgetElement',
  },
  {
    path: '**', // this allows the routing for routingstrategy: full
    redirectTo: '',
    pathMatch: 'full',
  },
];

// we use the RouterTesting module instead of the normal Router because the routing of this app has 3 different options
// (See global variable RoutingStrategy), and the most important one needs routing without URL-address interaction
@NgModule({
  imports: [RouterTestingModule.withRoutes(routes)],
  exports: [RouterTestingModule],
})
export class AppRoutingModule {}
