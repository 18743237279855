import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalsService } from '../../../shared/services/globals.service';
import { DsUtilitiesService } from '../../../shared/services/dsUtilities.service';
import { SDOAdapter } from 'schema-org-adapter';
import { SdoAdapterService } from '../../../shared/services/sdoAdapter.service';
import { UtilitiesService } from '../../../shared/services/utilities.service';
import { EnumerationMemberTableItem } from '../../../shared/types/table.type';
import { MatTableDataSource } from '@angular/material/table';

import { DsUtilitiesV7 } from 'ds-utilities';
import { DsV7, EnumerationMemberNodeV7, RestrictedEnumerationNodeV7 } from 'ds-utilities';
import { Subscription } from 'rxjs';
import { DialogService } from '../../../shared/services/dialog.service';

@Component({
  selector: 'native-enumeration-table',
  templateUrl: './native-enumeration-table.component.html',
  styleUrls: ['./native-enumeration-table.component.scss'],
})
export class NativeEnumerationTableComponent implements OnInit, OnDestroy {
  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[] = ['enumerationMember', 'description'];
  private currentNode: RestrictedEnumerationNodeV7; // the current DS Node that should be rendered
  private dsUtilities: DsUtilitiesV7;
  private sdoAdapter: SDOAdapter;
  private ds: DsV7; // the current DS (Populated)
  private globalsSub: Subscription;

  constructor(
    private GlobalsService: GlobalsService,
    private DsUtilitiesService: DsUtilitiesService,
    private SdoAdapterService: SdoAdapterService,
    private UtilitiesService: UtilitiesService,
    private DialogService: DialogService,
  ) {
    this.dataSource = new MatTableDataSource();
  }

  async ngOnInit(): Promise<void> {
    this.dsUtilities = this.DsUtilitiesService.getDsUtilities();
    this.UtilitiesService.initializeComponentWithSub(this, 'ds');
  }

  ngOnDestroy(): void {
    this.UtilitiesService.unSubIfExists([this.globalsSub]);
  }

  render(): void {
    if (!this.ds) {
      return;
    }
    this.currentNode = this.GlobalsService.getCurrentDsNode() as RestrictedEnumerationNodeV7;
    this.sdoAdapter = this.GlobalsService.getGlobal('sdoAdapter');
    const tableItems: EnumerationMemberTableItem[] = this.currentNode['sh:in'].map((el: any) => {
      return this.createTableItem(el);
    });
    this.dataSource = new MatTableDataSource(tableItems);
  }

  // creates an object holding information about an enumeration member, which is needed to render the table
  createTableItem(enumerationMemberNode: EnumerationMemberNodeV7): EnumerationMemberTableItem {
    const enumMemberLabel = this.dsUtilities.prettyPrintCompactedIRIs(enumerationMemberNode['@id']);
    const enumMemberURL = this.SdoAdapterService.getTermReferenceUrl(enumerationMemberNode['@id'], this.sdoAdapter);
    const descriptionFromVocab = this.UtilitiesService.repairLinksInHTMLCode(
      this.sdoAdapter.getEnumerationMember(enumerationMemberNode['@id']).getDescription(),
    );
    const id = enumerationMemberNode['@id'];
    const hasAdvancedConstraints = this.UtilitiesService.hasAdvancedConstraints(
      enumerationMemberNode,
      'EnumerationMember',
    );
    return {
      enumMemberLabel,
      enumMemberURL,
      descriptionFromVocab,
      id,
      hasAdvancedConstraints,
    };
  }

  // opens a dialog showing the advanced constraints of a term (ds path given)
  public openEnumMemberDialog(enumMemberId: string): void {
    this.DialogService.openEnumMemberDialog(
      this.ds,
      this.GlobalsService.getGlobal('pathDs'),
      enumMemberId,
      this.dsUtilities,
      this.sdoAdapter,
    );
  }

  public t(str: string): string {
    return 'native-enumeration-table.' + str;
  }
}
