<div class="button-wrapper" *ngIf="showJson">
  <button
    class="mat-btn onl-button"
    type="button"
    mat-flat-button="mat-flat-button"
    color="primary"
    [cdkCopyToClipboard]="ds | json"
    [cdkCopyToClipboardAttempts]="3"
    onclick="this.blur()"
    (click)="onCopy()"
  >
    <mat-icon>file_copy</mat-icon><span [translate]="t('copy-jsonld')"></span>
  </button>
</div>
<div class="text-center" *ngIf="!showJson">
  <div class="loading-wrapper">
    <mat-spinner></mat-spinner>
  </div>
</div>
<div class="shacl-wrapper" *ngIf="showJson">
  <ngx-json-viewer class="json-viewer" [json]="ds"></ngx-json-viewer>
</div>
