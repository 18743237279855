<div class="text-center" *ngIf="dataSource?.filteredData.length === 0">
  <div class="loading-wrapper">
    <mat-spinner></mat-spinner>
  </div>
</div>
<div *ngIf="dataSource?.filteredData.length > 0">
  <table class="mat-elevation-z8 bordered" mat-table="mat-table" matSort="matSort" [dataSource]="dataSource">
    <ng-container matColumnDef="icon">
      <th class="icon" mat-header-cell *matHeaderCellDef></th>
      <td class="icon" mat-cell="mat-cell" *matCellDef="let element">
        <div *ngIf="element.icon">
          <mat-icon class="margin-0 color-primary">{{ element.icon }}</mat-icon>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="property">
      <th
        class="property"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="propertyLabel"
        [title]="t('hover-table-header-property') | translate"
      >
        <span translate="shared.property"></span>
      </th>
      <td class="property hover-copy" mat-cell="mat-cell" *matCellDef="let element">
        <p class="margin-0 text-max-length-container">
          <b class="text-max-length" [title]="element.propertyLabel">{{ element.propertyLabel }}</b
          ><span class="margin-left-5"></span
          ><span *ngIf="element.isFromSuperDs"
            ><a
              target="_blank"
              [href]="superDsIRI"
              [title]="('shared.hover-property-super-ds' | translate) + ('shared.hover-click-open' | translate)"
            >
              <button class="inline-icon-button" mat-icon-button="mat-icon-button" color="primary">
                <mat-icon>arrow_circle_down</mat-icon>
              </button></a
            ></span
          ><span class="copy-icon" *ngIf="element.propertyURL"
            ><a (click)="openTermDialog(element.currentPath)" [title]="t('hover-property-term') | translate">
              <button class="inline-icon-button" mat-icon-button="mat-icon-button" color="primary">
                <mat-icon *ngIf="element.hasAdvancedConstraints">info</mat-icon>
                <mat-icon class="material-icons-outlined" *ngIf="!element.hasAdvancedConstraints">info</mat-icon>
              </button></a
            ></span
          >
        </p>
        <p class="rdfs-label" *ngIf="element.propertyRdfsLabels">
          <span *ngFor="let rdfsLabel of element.propertyRdfsLabels; index as i"
            ><span class="property-rdfs-label-lang">{{ rdfsLabel['@language'] }}:</span
            ><span class="text-max-length-container"
              ><span class="property-rdfs-label-value margin-left-5 text-max-length" [title]="rdfsLabel['@value']">{{
                rdfsLabel['@value']
              }}</span></span
            ><br
          /></span>
        </p>
      </td>
    </ng-container>
    <ng-container matColumnDef="cardinality">
      <th
        class="cardinality text-center"
        mat-header-cell
        *matHeaderCellDef
        [title]="t('hover-table-header-cardinality') | translate"
      >
        <div mat-sort-header="cardinalityOrder"><span translate="shared.cardinality"></span></div>
      </th>
      <td
        class="cardinality text-center"
        mat-cell="mat-cell"
        *matCellDef="let element"
        [title]="element.cardinalityTitle"
      >
        <span>{{ element.cardinalityText }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="ranges">
      <th class="ranges" mat-header-cell *matHeaderCellDef><span translate="shared.ranges"></span></th>
      <td class="ranges hover-copy" mat-cell="mat-cell" *matCellDef="let element">
        <span *ngFor="let range of element.ranges; index as i"
          ><span class="text-max-length-container"
            ><span *ngIf="range.nodeType === 'DataType'" [title]="range.rangeLabel">{{ range.rangeLabel }}</span
            ><a
              class="text-max-length primary-link font-weight-bold"
              *ngIf="range.nodeType !== 'DataType'"
              (click)="changePath(range.newPath)"
              [attr.href]="range.viewUrl"
              [title]="range.rangeLabel"
              >{{ range.rangeLabel }}</a
            ><span class="margin-left-5"></span
            ><span *ngIf="range.nodeType === 'InternalReference' || range.nodeType === 'InternalExternalReference'">
              <button
                class="inline-icon-button"
                mat-icon-button="mat-icon-button"
                [title]="range.externalURL + ' ' + ('shared.hover-int-ref' | translate)"
                color="primary"
              >
                <mat-icon>file_open</mat-icon>
              </button></span
            ><span *ngIf="range.nodeType === 'RootReference'">
              <button
                class="inline-icon-button"
                mat-icon-button="mat-icon-button"
                [title]="'shared.hover-root-ref' | translate"
                color="primary"
              >
                <mat-icon>restore_page</mat-icon>
              </button></span
            ><span *ngIf="range.nodeType === 'ExternalReference'"
              ><a
                target="_blank"
                [href]="range.externalURL"
                [title]="
                  range.externalURL +
                  ' ' +
                  ('shared.hover-ext-ref' | translate) +
                  ('shared.hover-click-open' | translate)
                "
              >
                <button class="inline-icon-button" mat-icon-button="mat-icon-button" color="primary">
                  <mat-icon>note_add</mat-icon>
                </button></a
              ></span
            ><span class="copy-icon"
              ><a (click)="openTermDialog(range.newPath)" [title]="t('hover-property-range') | translate">
                <button class="inline-icon-button" mat-icon-button="mat-icon-button" color="primary">
                  <mat-icon *ngIf="range.hasAdvancedConstraints">info</mat-icon>
                  <mat-icon class="material-icons-outlined" *ngIf="!range.hasAdvancedConstraints">info</mat-icon>
                </button></a
              ></span
            ></span
          ><br
        /></span>
      </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th class="description" mat-header-cell *matHeaderCellDef><span translate="shared.description"></span></th>
      <td class="description onl-body2" mat-cell="mat-cell" *matCellDef="let element">
        <p class="text-justify" *ngIf="element.descriptionFromDs">
          <span class="font-italic" *ngIf="element.descriptionFromVocab" [translate]="t('desc-ds')"></span
          ><span [innerHTML]="element.descriptionFromDs"></span>
        </p>
        <p class="text-justify" *ngIf="element.descriptionFromVocab">
          <span class="font-italic" *ngIf="element.descriptionFromDs" [translate]="t('desc-vocab')"></span
          ><span [innerHTML]="element.descriptionFromVocab"></span>
        </p>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
