import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalsService } from '../../shared/services/globals.service';
import { NavigationService } from '../../shared/services/navigation.service';
import { Subscription } from 'rxjs';
import { UrlBarSyncService } from '../../shared/services/urlBarSync.service';
import { DsList } from '../../shared/types/dsList.type';
import { UtilitiesService } from '../../shared/services/utilities.service';

@Component({
  selector: 'list-button',
  templateUrl: './list-button.component.html',
  styleUrls: ['./list-button.component.scss'],
})
export class ListButtonComponent implements OnInit, OnDestroy {
  private list: DsList;
  public listName: string;
  public listViewUrl: string;
  public showListButton = false; // switch for showing this button or not - depends upon if there is currently a list selected
  private globalsSub: Subscription;

  constructor(
    private GlobalsService: GlobalsService,
    private NavigationService: NavigationService,
    private UrlBarSyncService: UrlBarSyncService,
    private UtilitiesService: UtilitiesService,
  ) {}

  ngOnInit(): void {
    this.UtilitiesService.initializeComponentWithSub(this, 'list');
  }

  ngOnDestroy(): void {
    this.UtilitiesService.unSubIfExists([this.globalsSub]);
  }

  render(): void {
    if (this.list) {
      this.listName = this.UtilitiesService.getMetaValue(this.list['schema:name']);
      const routingStrategy = this.GlobalsService.getGlobal('routingStrategy');
      if (routingStrategy) {
        this.listViewUrl = this.UrlBarSyncService.createNewUrl(routingStrategy, {
          dsUID: undefined,
          listUID: this.GlobalsService.getGlobal('listUID'),
          pathUrl: undefined,
          viewMode: undefined,
        });
      }
      this.showListButton = true;
    } else {
      this.showListButton = false;
    }
  }

  // button click -> go back to list view
  navigateToList(): boolean {
    this.NavigationService.navigation({
      dsUID: undefined,
      ds: undefined,
      pathDs: undefined,
      pathUrl: undefined,
      viewMode: undefined,
    });
    this.UrlBarSyncService.setUrlChanges('new');
    return false; // to not trigger a history entry for the browser
  }

  public t(str: string): string {
    return 'list-button.' + str;
  }
}
