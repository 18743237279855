import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GlobalsService } from '../shared/services/globals.service';
import { UtilitiesService } from '../shared/services/utilities.service';
import { DsList } from '../shared/types/dsList.type';
import { RetrievalService } from '../shared/services/retrieval.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationService } from '../shared/services/navigation.service';
import { UrlBarSyncService } from '../shared/services/urlBarSync.service';
import { Subscription } from 'rxjs';
import { RoutingStrategy } from '../shared/types/globals.type';

type listItemType = {
  name: string;
  iri: string;
  description: string;
};

@Component({
  selector: 'list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
})
export class ListViewComponent implements OnInit, OnDestroy {
  public list: DsList;
  private routingStrategy: RoutingStrategy;
  public listName: string;
  public listDescription: string;
  public displayedColumns: string[] = ['name', 'iri', 'description'];
  private listItems: listItemType[] = [];
  public dataSource = new MatTableDataSource(this.listItems); // this is only here to set the right type
  listSub: Subscription;
  public listId: string;
  public showError = false;
  public errNoContent = true;
  public errorMessage: string;
  public loading = true;

  constructor(
    private GlobalsService: GlobalsService,
    private UtilitiesService: UtilitiesService,
    private RetrievalService: RetrievalService,
    private NavigationService: NavigationService,
    private UrlBarSyncService: UrlBarSyncService,
  ) {}

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  ngOnInit(): void {
    this.listId = this.GlobalsService.getGlobal('listUID');
    this.routingStrategy = this.GlobalsService.getGlobal('routingStrategy');
    if (this.listId) {
      this.listSub = this.RetrievalService.getList(this.listId).subscribe({
        next: (data) => {
          this.list = data;
          this.render();
        },
        error: (error) => {
          console.error(error);
          if (error.status === 404) {
            this.errNoContent = true;
          } else {
            this.errNoContent = false;
            this.errorMessage = error.error ? JSON.stringify(error.error, null, 2) : error.message;
          }
          this.loading = false;
          this.showError = true;
        },
      });
    }
  }

  ngOnDestroy(): void {
    this.UtilitiesService.unSubIfExists([this.listSub]);
  }

  render(): void {
    this.GlobalsService.setGlobal('list', this.list);
    this.listName = this.UtilitiesService.getMetaValue(this.list['schema:name']);
    this.listDescription = this.UtilitiesService.getMetaValue(this.list['schema:description']);
    this.listItems = this.list['schema:hasPart'].map((el) => {
      return {
        name: this.UtilitiesService.getMetaValue(el['schema:name']),
        description: this.UtilitiesService.getMetaValue(el['schema:description']),
        iri: el['@id'],
        url: this.getLinkUrlIfNeeded(el['@id']),
      };
    });
    this.dataSource = new MatTableDataSource(this.listItems);
    this.loading = false;
  }

  getLinkUrlIfNeeded(dsIri: string): string | null {
    if (!this.routingStrategy) {
      return null;
    }
    return this.UrlBarSyncService.createNewUrl(this.routingStrategy, {
      dsUID: this.UtilitiesService.extractUidFromIRI(dsIri),
      listUID: this.GlobalsService.getGlobal('listUID'),
      pathUrl: undefined, // should not be defined when entering a DS
      viewMode: this.GlobalsService.getGlobal('viewMode'),
    });
  }

  navigateToDs(dsIri: string): boolean {
    this.NavigationService.navigation({
      dsUID: this.UtilitiesService.extractUidFromIRI(dsIri),
    });
    this.UrlBarSyncService.setUrlChanges('new');
    return false; // to not trigger a history entry for the browser
  }

  public t(str: string): string {
    return 'list-view.' + str;
  }
}
