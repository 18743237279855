<div>
  <a *ngIf="showListButton" (click)="navigateToList()" [attr.href]="listViewUrl">
    <button class="mat-btn onl-button" mat-button>
      <mat-icon class="margin-0">arrow_back_ios</mat-icon>
      <span class="btn-text"
        ><span [translate]="t('list')"></span><span>: {{ listName }}</span></span
      >
    </button>
  </a>
</div>
