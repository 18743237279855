import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalsService } from '../shared/services/globals.service';
import { MatTabChangeEvent } from '@angular/material/tabs/tab-group';
import { ViewMode } from '../shared/types/globals.type';
import { UrlBarSyncService } from '../shared/services/urlBarSync.service';
import { Subscription } from 'rxjs';
import { NavigationService } from '../shared/services/navigation.service';
import { take } from 'rxjs/operators';
import { RetrievalService } from '../shared/services/retrieval.service';
import { UtilitiesService } from '../shared/services/utilities.service';
import { SDOAdapter } from 'schema-org-adapter';
import { SdoAdapterService } from '../shared/services/sdoAdapter.service';

@Component({
  selector: 'ds-view',
  templateUrl: './ds-view.component.html',
  styleUrls: ['./ds-view.component.scss'],
})
export class DsViewComponent implements OnInit, OnDestroy {
  viewMode: ViewMode; // important for the .selected class for navigation buttons
  tabIndex = 0; // index of the actual tab - depends on the selected view
  changeFromObserver = false; // switch that helps us to identify if a viewMode change comes from a tab-click or from the browser history navigation (back button)
  private navigationSub: Subscription;
  private dsSub: Subscription; // subscription for DS
  private listSub: Subscription; // subscription for DS
  public dsId: string;
  public showError = false;
  public errNoContent = true;
  public errorMessage: string;
  public sdoAdapter: SDOAdapter;
  public loading = true;

  constructor(
    private GlobalsService: GlobalsService,
    private UrlBarSyncService: UrlBarSyncService,
    private NavigationService: NavigationService,
    private RetrievalService: RetrievalService,
    private UtilitiesService: UtilitiesService,
    private SdoAdapterService: SdoAdapterService,
  ) {}

  ngOnInit(): void {
    // fetch DS
    this.dsId = this.GlobalsService.getGlobal('dsUID');
    this.dsSub = this.RetrievalService.getDs(this.dsId, true).subscribe({
      next: async (ds) => {
        this.sdoAdapter = await this.SdoAdapterService.getSdoAdapter(ds);
        this.GlobalsService.setGlobals({ ds: ds, sdoAdapter: this.sdoAdapter });
        this.render();
      },
      error: (error) => {
        console.error(error);
        if (error.status === 404) {
          this.errNoContent = true;
        } else {
          this.errNoContent = false;
          this.errorMessage = error.error ? JSON.stringify(error.error, null, 2) : error.message;
        }
        this.loading = false;
        this.showError = true;
      },
    });
    // fetch List, if needed
    const listUID = this.GlobalsService.getGlobal('listUID');
    if (listUID) {
      this.listSub = this.RetrievalService.getList(listUID)
        .pipe(take(1))
        .subscribe((data) => {
          this.GlobalsService.setGlobal('list', data);
        });
    }
    // render for current viewMode
    this.viewMode = this.GlobalsService.getGlobal('viewMode');
    // subscription for the viewMode changes
    this.navigationSub = this.NavigationService.navigationObserver.subscribe(() => {
      const newViewMode = this.GlobalsService.getGlobal('viewMode');
      if (newViewMode !== this.viewMode) {
        this.changeFromObserver = true;
        this.viewMode = newViewMode;
        this.render();
      }
    });
  }

  ngOnDestroy() {
    this.UtilitiesService.unSubIfExists([this.navigationSub, this.dsSub, this.listSub]);
  }

  // updates the current selected tab to reflect the wished viewMode
  render(): void {
    if (this.viewMode === 'tree') {
      this.tabIndex = 2;
    } else if (this.viewMode === 'shacl') {
      this.tabIndex = 1;
    } else {
      this.tabIndex = 0;
    }
    this.loading = false;
  }

  // event-listener for tab switch
  onTabClick(event: MatTabChangeEvent) {
    // This should be a click-listener, but also triggers when the tab is changed programmatically.
    // Here we differentiate between these 2 cases and proceed only for click events.
    if (this.changeFromObserver) {
      this.changeFromObserver = false;
      return;
    }
    switch (event.index) {
      case 0:
        this.viewMode = undefined;
        break;
      case 1:
        this.viewMode = 'shacl';
        break;
      case 2:
        this.viewMode = 'tree';
        break;
    }
    // update the navigation variables and the URL if needed
    this.NavigationService.navigation({ viewMode: this.viewMode });
    this.UrlBarSyncService.setUrlChanges('new');
  }

  public t(str: string): string {
    return 'ds-view.' + str;
  }
}
