import { Injectable } from '@angular/core';
import { SOA } from 'schema-org-adapter';
import { SDOAdapter } from 'schema-org-adapter';
import { DsUtilitiesService } from './dsUtilities.service';
import { DsV7 } from 'ds-utilities';

type sdoAdapterCacheEntry = {
  schemaVersion: string;
  vocabularies: string[];
  sdoAdapter: SDOAdapter;
};

@Injectable()
export class SdoAdapterService {
  // a cache for created SDO-Adapter
  private sdoAdapterCache: sdoAdapterCacheEntry[] = [];

  constructor(private DsUtilitiesService: DsUtilitiesService) {}

  // returns an SDO-Adapter for the given DS
  public async getSdoAdapter(ds: DsV7): Promise<SDOAdapter> {
    const dsUtilities = this.DsUtilitiesService.getDsUtilities();
    const schemaVersion = dsUtilities.getDsSchemaVersion(ds);
    const vocabularies = dsUtilities.getDsExternalVocabularies(ds);
    const match = this.getSdoAdapterFromCache(schemaVersion, vocabularies);
    if (match) {
      return match;
    } else {
      const newSdoAdapter = await SOA.create({
        schemaVersion: dsUtilities.getDsSchemaVersion(ds),
        vocabularies: dsUtilities.getDsExternalVocabularies(ds),
      });
      this.sdoAdapterCache.push({
        schemaVersion,
        vocabularies,
        sdoAdapter: newSdoAdapter,
      });
      return newSdoAdapter;
    }
  }

  // get a specific SDO-Adapter from the cache, if available
  getSdoAdapterFromCache(schemaVersion: string, vocabularies: string[]): SDOAdapter {
    const vocabMatchFn = (vocabulary: string) => vocabularies.includes(vocabulary);
    const match = this.sdoAdapterCache.find((el) => {
      if (el.schemaVersion !== schemaVersion) {
        return false;
      }
      if (el.vocabularies.length !== vocabularies.length) {
        return false;
      }
      return el.vocabularies.every(vocabMatchFn);
    });
    if (match) {
      return match.sdoAdapter;
    } else {
      return null;
    }
  }

  // resolves a reference URL for a given vocabulary term, with the help of the corresponding SDO-Adapter
  getTermReferenceUrl(compactIri: string, sdoAdapter: SDOAdapter): string {
    const termObj = sdoAdapter.getTerm(compactIri);
    const vocabURLs = termObj.getVocabURLs();
    if (vocabURLs) {
      // check if the vocabulary is NOT schema.org, and therefore assumed to be hosted at semantify.it
      for (const vocabURL of vocabURLs) {
        if (/http(s)?:\/\/.*?\/voc\//.test(vocabURL)) {
          return vocabURL + '?term=' + compactIri;
        }
      }
    }
    return termObj.getIRI();
  }
}
