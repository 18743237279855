<div class="dialog-title-container">
  <button
    class="dialog-close-button"
    mat-icon-button="mat-icon-button"
    (click)="close()"
    [title]="'shared.close' | translate"
  >
    <mat-icon>close</mat-icon>
  </button>
  <h1 mat-dialog-title="mat-dialog-title">{{ datatypeName }}</h1>
  <p class="mat-dialog-title-sub">DataType ({{ datatypeXsd }})</p>
</div>
<div mat-dialog-content="mat-dialog-content">
  <table class="details-table">
    <tr>
      <th [title]="t('iri-hover') | translate">{{ t('iri') | translate }}:</th>
      <td>
        {{ uri
        }}<a class="margin-left-5" target="_blank" [href]="uriLink" [title]="t('hover-iri-icon') | translate">
          <button class="inline-icon-button" mat-icon-button="mat-icon-button" color="primary">
            <mat-icon>launch</mat-icon>
          </button></a
        >
      </td>
    </tr>
    <tr>
      <th [title]="t('labels-hover') | translate">{{ t('labels') | translate }}:</th>
      <!--({{labels.length}})-->
      <td>
        <ul *ngIf="labels.length > 0">
          <li *ngFor="let l of labels">
            <span class="property-rdfs-lang">{{ l['@language'] }}:</span
            ><span class="property-rdfs-value margin-left-5">{{ l['@value'] }}</span>
          </li>
        </ul>
        <span *ngIf="labels.length === 0">-</span>
      </td>
    </tr>
    <tr>
      <th [title]="t('comments-hover') | translate">{{ t('comments') | translate }}:</th>
      <!--({{comments.length}})-->
      <td>
        <ul *ngIf="comments.length > 0">
          <li *ngFor="let c of comments">
            <span class="property-rdfs-lang">{{ c['@language'] }}:</span
            ><span class="property-rdfs-value margin-left-5">{{ c['@value'] }}</span>
          </li>
        </ul>
        <span *ngIf="comments.length === 0">-</span>
      </td>
    </tr>
    <!-- language-->
    <tr *ngIf="langUnique !== undefined">
      <th [title]="t('lang-unique-hover') | translate">{{ t('lang-unique') | translate }}:</th>
      <td>{{ langUnique }}</td>
    </tr>
    <tr *ngIf="langHas.length > 0">
      <th [title]="t('lang-has-hover') | translate">{{ t('lang-has') | translate }}:</th>
      <td>{{ langHas.join(', ') }}</td>
    </tr>
    <tr *ngIf="langIn.length > 0">
      <th [title]="t('lang-in-hover') | translate">{{ t('lang-in') | translate }}:</th>
      <td>{{ langIn.join(', ') }}</td>
    </tr>
    <tr *ngIf="langDefault.length > 0">
      <th [title]="t('lang-default-hover') | translate">{{ t('lang-default') | translate }}:</th>
      <td>{{ langDefault.join(', ') }}</td>
    </tr>
    <!-- value-->
    <tr *ngIf="valueHas.length > 0">
      <th [title]="t('value-has-hover') | translate">{{ t('value-has') | translate }}:</th>
      <td>{{ valueHas.join(', ') }}</td>
    </tr>
    <tr *ngIf="valueIn.length > 0">
      <th [title]="t('value-in-hover') | translate">{{ t('value-in') | translate }}:</th>
      <td>{{ valueIn.join(', ') }}</td>
    </tr>
    <tr *ngIf="valueDefault">
      <th [title]="t('value-default-hover') | translate">{{ t('value-default') | translate }}:</th>
      <td>{{ valueDefault }}</td>
    </tr>
    <!-- min-max-->
    <tr *ngIf="minInclusive">
      <th [title]="t('min-inc-hover') | translate">{{ t('min-inc') | translate }}:</th>
      <td>{{ minInclusive }}</td>
    </tr>
    <tr *ngIf="maxInclusive">
      <th [title]="t('max-inc-hover') | translate">{{ t('max-inc') | translate }}:</th>
      <td>{{ maxInclusive }}</td>
    </tr>
    <tr *ngIf="minExclusive">
      <th [title]="t('min-exc-hover') | translate">{{ t('min-exc') | translate }}:</th>
      <td>{{ minExclusive }}</td>
    </tr>
    <tr *ngIf="maxExclusive">
      <th [title]="t('max-exc-hover') | translate">{{ t('max-exc') | translate }}:</th>
      <td>{{ maxExclusive }}</td>
    </tr>
    <!-- length-->
    <tr *ngIf="lengthMin">
      <th [title]="t('length-min-hover') | translate">{{ t('length-min') | translate }}:</th>
      <td>{{ lengthMin }}</td>
    </tr>
    <tr *ngIf="lengthMax">
      <th [title]="t('length-max-hover') | translate">{{ t('length-max') | translate }}:</th>
      <td>{{ lengthMax }}</td>
    </tr>
    <!-- regex-->
    <tr *ngIf="regex.length > 0">
      <th [title]="t('regex-hover') | translate">{{ t('regex') | translate }}:</th>
      <td>
        <p class="regex" *ngFor="let r of regex">{{ r }}</p>
      </td>
    </tr>
    <tr *ngIf="regexFlags">
      <th [title]="t('regex-flags-hover') | translate">{{ t('regex-flags') | translate }}:</th>
      <td>{{ regexFlags }}</td>
    </tr>
  </table>
</div>
<div mat-dialog-actions="mat-dialog-actions">
  <button class="mat-button onl-button" (click)="close()" mat-flat-button="mat-flat-button" color="primary">
    {{ 'shared.close' | translate }}
  </button>
</div>
