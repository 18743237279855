// since TypeScript lacks regular-expression-validated string types, we come up with a workaround, src: https://stackoverflow.com/questions/37144672/guid-uuid-type-in-typescript
export type TreeLocationCoordinates = string & { _tlcBrand: undefined };

// TLC consists of tokens (token = number > 0 ) separated by ".". There must be at least 1 token. e.g. "1", "1.1", "3.123.12"
export function createTLC(text: string): TreeLocationCoordinates {
  const tokenRegex = new RegExp(/^[1-9][0-9]*$/);
  const tokens = text.split('.');
  if (tokens.length === 0) {
    throw new Error('TLC must have at least 1 token!');
  }
  tokens.forEach((t) => {
    if (!tokenRegex.test(t)) {
      throw new Error(`Token ${t} is not allowed!`);
    }
  });
  return text as TreeLocationCoordinates;
}
