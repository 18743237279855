import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { DsUtilitiesV7 } from 'ds-utilities';
import { TreeProvider } from '../../tree-provider/tree-provider.class';
import { createTLC, TreeLocationCoordinates } from '../../tree-provider/types/tree-location-coordinates.type';
import { TreeNode } from '../../tree-provider/types/tree-node.type';
import { TreeTileConfig } from './tree-tile/types/tree-tile-config.type';
import { DsUtilitiesService } from '../../../../../shared/services/dsUtilities.service';
import { TreeControlService } from '../../tree-control/treeControl.service';
import { PathGrammarNodeTypeV7 } from 'ds-utilities/lib/v7/types/PathGrammarV7.type';
import { DsGrammarNodeTypeV7 } from 'ds-utilities/lib/v7/types/DsGrammarV7.type';
import { NavigationService } from '../../../../../shared/services/navigation.service';
import { StartNode } from './types/startNode.type';
import { UrlBarSyncService } from '../../../../../shared/services/urlBarSync.service';

@Component({
  selector: 'td[table-cell-tree]',
  templateUrl: './table-cell-tree.component.html',
  styleUrls: ['./table-cell-tree.component.scss'],
})
export class TableCellTreeComponent implements OnInit {
  @Input() tlc: TreeLocationCoordinates;
  @Input() treeProvider: TreeProvider;
  private dsUtil: DsUtilitiesV7;

  @HostBinding('class') classArray: string[] = ['tree-cell'];
  treeNode: TreeNode;
  label: string = '';
  labelClass: string = '';
  treeTileConfigs: TreeTileConfig[] = [];
  startNodeType: StartNode;
  startNodeLink: string;
  referenceIcon: string; // the icon id for the reference
  referenceTitle: string; // the translation id for the reference hover text
  hasNativeViewLink: boolean = false;

  constructor(
    private DsUtilitiesService: DsUtilitiesService,
    private TreeControlService: TreeControlService,
    private NavigationService: NavigationService,
    private UrlBarSyncService: UrlBarSyncService,
  ) {}

  ngOnInit(): void {
    this.dsUtil = this.DsUtilitiesService.getDsUtilities();
    this.treeNode = this.treeProvider.getNodeAtTLC(this.tlc);
    // tree tiles content
    this.setTreeTilesContent();
    // label content
    this.setLabelContent();
  }

  // based on this row's TLC create corresponding tile configurations in 'treeTileConfigs' for the tree grid
  setTreeTilesContent() {
    const tlcTokens = this.tlc.split('.');
    for (let i = 1; i <= tlcTokens.length; i++) {
      const newTile: TreeTileConfig = {
        pattern: 'blank',
      };
      let tlcTokensCopy = [...tlcTokens];
      tlcTokensCopy[i - 1] = (+tlcTokensCopy[i - 1] + 1).toString();
      tlcTokensCopy = tlcTokensCopy.slice(0, i); // cut off the rest
      const tlcToCheck = tlcTokensCopy.join('.');
      newTile.lineType = i % 2 === 0 ? 'solid' : 'dashed';
      if (this.treeProvider.getNodeAtTLC(createTLC(tlcToCheck))) {
        if (i === tlcTokens.length) {
          newTile.pattern = 'top-right-down';
        } else {
          newTile.pattern = 'top-down';
        }
      } else {
        if (i === tlcTokens.length) {
          newTile.pattern = 'top-right';
        } else {
          newTile.pattern = 'blank'; // stays blank
        }
      }
      if (
        tlcTokens.length === i &&
        (this.treeNode.originNodeType === 'RootNode' ||
          this.treeNode.originNodeType === 'Property' ||
          this.treeNode.originNodeType === 'RestrictedClass')
      ) {
        newTile.hasToggler = true;
      }
      this.treeTileConfigs.push(newTile);
    }
  }

  // set the content, class and eventually icon for depending on the current tree node type/content
  setLabelContent() {
    if (this.treeNode.nodeType === 'Property') {
      this.label = this.dsUtil.prettyPrintCompactedIRIs(this.treeNode.dsNode['sh:path']);
      this.labelClass = 'label-property';
    } else if (
      // Class / Enumeration
      this.treeNode.nodeType === 'RootNode' ||
      this.treeNode.nodeType === 'RestrictedClass' ||
      this.treeNode.nodeType === 'RestrictedEnumeration' ||
      this.treeNode.nodeType === 'StandardClass' ||
      this.treeNode.nodeType === 'StandardEnumeration'
    ) {
      this.label = this.dsUtil.prettyPrintCompactedIRIs(this.treeNode.dsNode['sh:class']);
      this.labelClass = 'label-class';
      this.hasNativeViewLink = true;
      // set icon for root nodes
      if (this.tlc.split('.').length === 1) {
        if (this.tlc === '1') {
          // root node
          this.startNodeType = 'root';
        } else {
          const rootNodeURI = this.treeProvider.getTree()[0].dsNode['@id'];
          if (this.treeNode.dsNode['@id'].startsWith(rootNodeURI)) {
            // internal ref
            this.startNodeType = 'internal';
          } else if (this.treeNode.dsNode['@id'].includes('#')) {
            // external internal ref
            this.startNodeType = 'externalInternal';
            this.startNodeLink = this.treeNode.dsNode['@id'];
          } else {
            // external ref
            this.startNodeType = 'external';
            this.startNodeLink = this.treeNode.dsNode['@id'];
          }
        }
      }
      // set icon for reference
      if (this.treeNode.originNodeType.includes('Reference')) {
        switch (this.treeNode.originNodeType) {
          case 'ExternalReference':
            this.referenceIcon = 'note_add';
            this.referenceTitle = 'shared.hover-ext-ref';
            break;
          case 'InternalExternalReference':
            this.referenceIcon = 'note_add';
            this.referenceTitle = 'shared.hover-ext-int-ref';
            break;
          case 'InternalReference':
            this.referenceIcon = 'file_open';
            this.referenceTitle = 'shared.hover-int-ref';
            break;
          case 'RootReference':
            this.referenceIcon = 'restore_page';
            this.referenceTitle = 'shared.hover-root-ref';
            break;
        }
      }
    } else if (this.treeNode.nodeType === 'DataType') {
      this.label = this.dsUtil.getDataTypeLabel(this.treeNode.dsNode['sh:datatype']);
    }
  }

  scrollToReference() {
    this.TreeControlService.scrollToRow(this.tlc, this.treeProvider);
  }

  openInNativeView() {
    // this.treeNode
    const treeNodeStack: TreeNode[] = [];
    // add tree nodes from actual node to root to the stack
    const tlcTokens = this.tlc.split('.');
    for (let i = 0; i < tlcTokens.length; i++) {
      treeNodeStack.push(this.treeProvider.getNodeAtTLC(createTLC(tlcTokens.slice(0, tlcTokens.length - i).join('.'))));
    }
    let path;
    while (treeNodeStack.length > 0) {
      const nextNode = treeNodeStack.pop();
      if (!path) {
        // start
        // https://semantifyit.github.io/ds-utilities/classes/DsUtilitiesV7.html#dsPathInit
        let inputForPathInit = getPathTypeForNodeType(nextNode.originNodeType);
        const inputId = nextNode.dsNode['@id'];
        const rootNodeId = this.treeProvider.getNodeAtTLC(createTLC('1')).dsNode['@id'];
        if (inputForPathInit !== 'RootNode') {
          // not the root node -> check which type fits
          if (inputId.includes('#')) {
            //inner node
            if (inputId.startsWith(rootNodeId)) {
              inputForPathInit = 'InternalReferenceDefinition';
            } else {
              inputForPathInit = 'InternalExternalReferenceDefinition';
            }
          } else {
            //if not inner node and not root node -> external ds
            inputForPathInit = 'ExternalReferenceDefinition';
          }
        }
        path = this.dsUtil.dsPathInit(inputForPathInit, inputId);
      } else {
        // addition
        let inputForPath;
        const pathType = getPathTypeForNodeType(nextNode.originNodeType);
        switch (pathType) {
          // DataType ommited https://semantifyit.github.io/ds-utilities/classes/DsUtilitiesV7.html#dsPathAddition
          case 'Property':
            inputForPath = nextNode.dsNode['sh:path'];
            break;
          case 'Class':
          case 'Enumeration':
            inputForPath = nextNode.dsNode['sh:class'];
            break;
          default:
            inputForPath = nextNode.dsNode['@id'];
            break;
        }
        path = this.dsUtil.dsPathAddition(path, pathType, inputForPath);
      }
    }
    // navigation trigger
    this.NavigationService.navigation({ pathDs: path, viewMode: undefined });
    this.UrlBarSyncService.setUrlChanges('new');
  }

  public t(str: string): string {
    return 'table-cell-tree.' + str;
  }
}

function getPathTypeForNodeType(nodeType: DsGrammarNodeTypeV7): PathGrammarNodeTypeV7 {
  switch (nodeType) {
    case 'StandardClass':
    case 'RestrictedClass':
      return 'Class';
    case 'StandardEnumeration':
    case 'RestrictedEnumeration':
      return 'Enumeration';
  }
  return nodeType as PathGrammarNodeTypeV7;
}
