import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-legend',
  templateUrl: './icon-legend.component.html',
  styleUrls: ['./icon-legend.component.scss'],
})
export class IconLegendComponent implements OnInit {
  iconLegendOpened = false;

  constructor() {}

  ngOnInit(): void {}

  iconLegendToggle(): boolean {
    this.iconLegendOpened = !this.iconLegendOpened;
    return false;
  }

  public t(str: string): string {
    return 'icon-legend.' + str;
  }
}
