import { Injectable } from '@angular/core';
import { Globals, GlobalsChanges } from '../types/globals.type';
import { DsUtilitiesService } from './dsUtilities.service';
import { Subject } from 'rxjs';

@Injectable()
export class GlobalsService {
  public globalsObserver = new Subject<GlobalsChanges>();

  // Global variables needed for the configuration and navigation of DSB
  private globals: Globals = {
    routingStrategy: undefined,
    listUID: undefined,
    list: undefined,
    dsUID: undefined,
    ds: undefined,
    pathDs: undefined,
    pathUrl: undefined, // this is only set at start if routingstrategy !== undefined and there was a path given in the url OR when users navigate with BACK/FORWARD buttons of the browser
    viewMode: undefined,
    sdoAdapter: undefined,
    isDvsHost: undefined,
  };

  constructor(private DsUtilitiesService: DsUtilitiesService) {}

  // set a single global variable
  public setGlobal<K extends keyof Globals>(identifier: K, value: Globals[K]): void {
    this.globals[identifier] = value;
    this.globalsObserver.next({
      [identifier]: value,
    });
  }

  // set multiple global variables
  public setGlobals<K extends keyof GlobalsChanges>(changes: GlobalsChanges): void {
    (Object.keys(changes) as K[]).forEach((key) => {
      this.globals[key] = changes[key] as Globals[K];
    });
    this.globalsObserver.next(changes);
  }

  // get a single global variable
  public getGlobal<K extends keyof Globals>(identifier: K): Globals[K] {
    return this.globals[identifier];
  }

  // get all global variables
  public getGlobals(): Globals {
    return this.globals;
  }

  // returns the current DS node (for the native view)
  public getCurrentDsNode(): object {
    return this.DsUtilitiesService.getDsNodeAtPath(this.getGlobal('ds'), this.getGlobal('pathDs'));
  }
}
