import { Injectable } from '@angular/core';
import { readUrlParameters, RoutingStrategy, urlParameters, ViewMode } from '../types/globals.type';
import { Router } from '@angular/router';
import { DsUtilitiesService } from './dsUtilities.service';
import { GlobalsService } from './globals.service';
import { DataTypeNodeV7, PropertyNodeV7 } from 'ds-utilities';
import { PathGrammarNodeTypeV7 } from 'ds-utilities';

@Injectable()
export class UrlBarSyncService {
  constructor(
    private Router: Router,
    private DsUtilitiesService: DsUtilitiesService,
    private GlobalsService: GlobalsService,
  ) {}

  // if any global variable has changed, then check if the URL address bar should be changed
  // the given state can be "new" for a new browser history entry, or "replace" to replace the current browser history entry
  setUrlChanges(state: string = 'new'): void {
    // the routing strategy should be set only once at startup, it is checked here to know if and how the url is changed in the url address bar
    const globals = this.GlobalsService.getGlobals();
    if (!globals.routingStrategy) {
      return; // ignore url bar completely
    }
    // creates the new URL for the address-bar, depending on the current routing-strategy
    const newUrl = this.createNewUrl(globals.routingStrategy, {
      dsUID: globals.dsUID,
      listUID: globals.listUID,
      pathUrl: this.encodePathToURL(globals.pathDs),
      viewMode: globals.viewMode,
    });
    if (state === 'new') {
      history.pushState(null, null, newUrl);
    } else if (state === 'replace') {
      history.replaceState(null, null, newUrl);
    }
  }

  // creates the new URL for the address-bar, depending on the current routing-strategy
  createNewUrl(routingStrategy: 'query' | 'full', urlAddress: urlParameters): string {
    let url: URL;
    if (routingStrategy === 'query') {
      url = new URL(window.location.href);
      this.setSearchParamForNewUrl(url.searchParams, 'dsb-ds', urlAddress.dsUID);
      this.setSearchParamForNewUrl(url.searchParams, 'dsb-list', urlAddress.listUID);
      this.setSearchParamForNewUrl(url.searchParams, 'dsb-mode', urlAddress.viewMode);
      this.setSearchParamForNewUrl(url.searchParams, 'dsb-path', urlAddress.pathUrl);
    } else {
      url = new URL(window.location.origin);
      let listPathPrefix = '/list/';
      let dsPathPrefix = '/ds/';
      if (this.GlobalsService.getGlobal('isDvsHost')) {
        listPathPrefix = '/api/dvs/list/';
        dsPathPrefix = '/api/dvs/ds/';
      }
      if (urlAddress.listUID) {
        url.pathname = listPathPrefix + urlAddress.listUID;
        if (urlAddress.dsUID) {
          this.setSearchParamForNewUrl(url.searchParams, 'ds', urlAddress.dsUID);
        }
      } else if (urlAddress.dsUID) {
        url.pathname = dsPathPrefix + urlAddress.dsUID;
      }
      this.setSearchParamForNewUrl(url.searchParams, 'mode', urlAddress.viewMode);
      this.setSearchParamForNewUrl(url.searchParams, 'path', urlAddress.pathUrl);
    }
    return url.toString();
  }

  // helper function
  setSearchParamForNewUrl(searchParams: URLSearchParams, parameterId: string, value?: string): void {
    if (value) {
      searchParams.set(parameterId, value);
    } else {
      searchParams.delete(parameterId);
    }
  }

  // reads the current global parameters given by the URL (only available if "routingStrategy" is given)
  readParametersFromURL(routingStrategy: RoutingStrategy): readUrlParameters {
    const result: readUrlParameters = {
      dsUID: undefined,
      listUID: undefined,
      pathUrl: undefined,
      viewMode: undefined,
      isDvsHost: undefined,
    };
    if (!routingStrategy) {
      return result;
    }
    if (routingStrategy === 'query') {
      const url = new URL(window.location.href);
      result.dsUID = url.searchParams.get('dsb-ds') ? url.searchParams.get('dsb-ds') : undefined;
      result.listUID = url.searchParams.get('dsb-list') ? url.searchParams.get('dsb-list') : undefined;
      result.viewMode = url.searchParams.get('dsb-mode') ? (url.searchParams.get('dsb-mode') as ViewMode) : undefined;
      result.pathUrl = url.searchParams.get('dsb-path') ? url.searchParams.get('dsb-path') : undefined;
    } else {
      const url = new URL(window.location.href);
      result.isDvsHost = url.pathname.startsWith('/api/dvs/');
      let listPathPrefix = '/list/';
      let dsPathPrefix = '/ds/';
      if (result.isDvsHost) {
        listPathPrefix = '/api/dvs/list/';
        dsPathPrefix = '/api/dvs/ds/';
      }
      if (url.pathname.startsWith(listPathPrefix)) {
        result.listUID = url.pathname.substring(listPathPrefix.length);
        result.dsUID = url.searchParams.get('ds') ? url.searchParams.get('ds') : undefined;
      } else if (url.pathname.startsWith(dsPathPrefix)) {
        result.dsUID = url.pathname.substring(dsPathPrefix.length);
        result.listUID = undefined;
      }
      result.pathUrl = url.searchParams.get('path') ? url.searchParams.get('path') : undefined;
      // backwards compatibility
      result.viewMode = url.searchParams.get('mode') ? (url.searchParams.get('mode') as ViewMode) : undefined;
      if (!result.viewMode && url.searchParams.get('format')) {
        result.viewMode = url.searchParams.get('format') as ViewMode;
      }
      if (result.viewMode !== 'shacl' && result.viewMode !== 'tree') {
        result.viewMode = undefined;
      }
    }
    // fix for MTE path in url -> " + " is written as "%20+%20" which is not correctly returned by the URLSearchParams class
    // backwards compatibility
    if (result.pathUrl && result.pathUrl.includes('   ')) {
      result.pathUrl = result.pathUrl.replace(new RegExp(' {3}', 'g'), ' + ');
    }
    return result;
  }

  // creates the corresponding global variable "pathUrl" for a given global variable "pathDs"
  encodePathToURL(pathDs: string): string {
    const ds = this.GlobalsService.getGlobal('ds');
    if (!pathDs || !ds) {
      return undefined;
    }
    const pathTokens = this.DsUtilitiesService.getDsUtilities().tokenizeDsPath(ds, pathDs);
    if (pathTokens.length === 1 && pathTokens[0].token === '$') {
      return undefined;
    } else {
      if (pathTokens[0].token === '$') {
        // starts with the root node - not shown in url path
        pathTokens.shift();
      }
      return pathTokens.map((el) => el.label).join('-');
    }
  }

  // creates the corresponding global variable "pathDs" for a given global variable "pathUrl"
  decodePathFromURL(pathUrl: string): string {
    let path: string;
    try {
      if (!pathUrl) {
        return path;
      } else {
        const tokens = pathUrl.split('-');
        for (const curT of tokens) {
          // check if the first letter of the actual token (vocabulary term - without indicator part ) starts with uppercase (class/enum) or not (property)
          if (curT.split(':').pop()[0].toUpperCase() !== curT.split(':').pop()[0]) {
            //property
            if (!path) {
              // start path, with root node
              path = this.DsUtilitiesService.getDsUtilities().dsPathInit('RootNode');
            }
            path = this.DsUtilitiesService.getDsUtilities().dsPathAddition(path, 'Property', this.decodePathURI(curT));
          } else {
            // class/enumeration/reference
            const target = this.decodePathToken(curT);
            if (!path) {
              // start path, with referenced node
              const ds = this.GlobalsService.getGlobal('ds');
              const rootNode = this.DsUtilitiesService.getDsUtilities().getDsRootNode(ds);
              const referencedNode = ds['@graph'].find((el) => this.checkMatch(el['sh:class'], target));
              if (!referencedNode) {
                throw new Error("There was no path match possible for '" + pathUrl + "'");
              }
              let startType: PathGrammarNodeTypeV7;
              // not the root node -> check which type fits
              if (referencedNode['@id'].includes('#')) {
                //inner node
                if (referencedNode['@id'].startsWith(rootNode['@id'])) {
                  startType = 'InternalReferenceDefinition';
                } else {
                  startType = 'InternalExternalReferenceDefinition';
                }
              } else {
                //if not inner node and not root node -> external ds
                startType = 'ExternalReferenceDefinition';
              }

              path = this.DsUtilitiesService.getDsUtilities().dsPathInit(startType, referencedNode['@id']);
            } else {
              // identify the matching range node for the current property node
              const nextTokenMatch: {
                additionType: PathGrammarNodeTypeV7;
                valueForPathAddition: string | string[];
              } = this.identifyRangeMatch(target, path);
              path = this.DsUtilitiesService.getDsUtilities().dsPathAddition(
                path,
                nextTokenMatch.additionType,
                nextTokenMatch.valueForPathAddition,
              );
            }
          }
        }
        return path;
      }
    } catch (e) {
      console.error(e);
      return '$'; // use root node
    }
  }

  // helper function - pass the dsPath of a propertyNode
  identifyRangeMatch(
    tokenTerms: string[],
    dsPath: string,
  ): {
    additionType: PathGrammarNodeTypeV7;
    valueForPathAddition: string | string[];
  } {
    const ds = this.GlobalsService.getGlobal('ds');
    const propertyNode: PropertyNodeV7 = this.DsUtilitiesService.getDsUtilities().dsPathGetNode(
      ds,
      dsPath,
      true,
    ) as PropertyNodeV7;
    for (const rangeNode of propertyNode['sh:or']) {
      if ((rangeNode as DataTypeNodeV7)['sh:datatype']) {
        // is a datatype node - check if match
        if ((rangeNode as DataTypeNodeV7)['sh:datatype'] === tokenTerms[0]) {
          return {
            additionType: 'DataType',
            valueForPathAddition: tokenTerms[0],
          };
        }
      } else if ((rangeNode as any)['sh:node']) {
        const shNode = (rangeNode as any)['sh:node'];
        const grammarNodeType = this.DsUtilitiesService.getDsUtilities().identifyDsGrammarNodeType(shNode, ds, false);
        if (grammarNodeType === 'RestrictedClass' || grammarNodeType === 'StandardClass') {
          //is a class node - check if match
          const match = this.checkMatch(tokenTerms, shNode['sh:class']);
          if (match) {
            return {
              additionType: 'Class',
              valueForPathAddition: tokenTerms,
            };
          }
        } else if (grammarNodeType === 'RestrictedEnumeration' || grammarNodeType === 'StandardEnumeration') {
          //is an enumeration node - check if match
          const match = this.checkMatch(tokenTerms, shNode['sh:class']);
          if (match) {
            return {
              additionType: 'Enumeration',
              valueForPathAddition: tokenTerms,
            };
          }
        } else {
          // is a reference - check if the referenced node is a match
          const referencedNode = ds['@graph'].find((el) => el['@id'] === shNode['@id']);
          const match = referencedNode && this.checkMatch(tokenTerms, referencedNode['sh:class']);
          if (match) {
            return {
              additionType: grammarNodeType as PathGrammarNodeTypeV7,
              valueForPathAddition: shNode['@id'],
            };
          }
        }
      }
    }
    throw new Error('Could not find a match for ' + tokenTerms);
  }

  // could be a mte like "Hotel + Restaurant"
  decodePathToken(token: string): string[] {
    const tokens = token.split(' + ');
    return tokens.map((x) => this.decodePathURI(x));
  }

  // add schema: if not used
  decodePathURI(iri: string): string {
    if (iri.includes(':')) {
      return iri;
    }
    return 'schema:' + iri;
  }

  checkMatch(tokenTerms: string[], classArray: string[]): boolean {
    return tokenTerms.every((x) => classArray.includes(x)) && classArray.every((x) => tokenTerms.includes(x));
  }

  // read the current pathUrl (most likely it has been previously been read from the URL-address) and update the corresponding pathDs
  setPathDsFromUrl(): void {
    const pathUrl = this.GlobalsService.getGlobal('pathUrl');
    this.GlobalsService.setGlobal('pathDs', this.decodePathFromURL(pathUrl));
  }

  // initialize pathDs if not given yet
  initializePathDs(): void {
    let pathDs = this.GlobalsService.getGlobal('pathDs');
    if (!pathDs) {
      const pathUrl = this.GlobalsService.getGlobal('pathUrl');
      if (pathUrl) {
        this.setPathDsFromUrl();
      } else {
        pathDs = this.DsUtilitiesService.getDsUtilities().dsPathInit('RootNode');
        this.GlobalsService.setGlobal('pathDs', pathDs);
      }
    }
  }
}
