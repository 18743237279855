import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SdoAdapterService } from '../../services/sdoAdapter.service';
import { DataTypeNodeV7, LanguageTaggedString } from 'ds-utilities';
import { DialogTermData } from '../../types/dialog-term-data.type';
import { isObject, isString } from '../../utilities';

@Component({
  selector: 'dialog-datatype',
  templateUrl: './dialog-datatype.component.html',
  styleUrls: ['./dialog-datatype.component.scss', './../../styles/term-dialog.scss'],
})
export class DialogDatatypeComponent implements OnInit {
  public datatypeName: string;
  public datatypeXsd: string;
  public uri: string;
  public uriLink: string;
  public labels: LanguageTaggedString[];
  public comments: LanguageTaggedString[];

  public langHas: string[] = [];
  public langIn: string[] = [];
  public langDefault: string[] = [];
  public langUnique: boolean;

  public valueHas: string[] = [];
  public valueIn: string[] = [];
  public valueDefault: string;

  public minInclusive: string;
  public maxInclusive: string;
  public minExclusive: string;
  public maxExclusive: string;

  public lengthMin: string;
  public lengthMax: string;
  public regex: string[] = [];
  public regexFlags: string;

  constructor(
    private SdoAdapterService: SdoAdapterService,
    public dialogRef: MatDialogRef<DialogDatatypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogTermData,
  ) {}

  ngOnInit(): void {
    const datatypeNode = this.data.dsNode as DataTypeNodeV7;
    this.datatypeXsd = datatypeNode['sh:datatype'];
    this.datatypeName = this.data.dsUtilities.getDataTypeLabel(datatypeNode['sh:datatype']);
    this.uri = this.data.dsUtilities.getSchemaDataTypeForDsDataType(datatypeNode['sh:datatype']);
    this.uriLink = this.SdoAdapterService.getTermReferenceUrl(this.uri, this.data.sdoAdapter);
    this.labels = datatypeNode['rdfs:label'] || [];
    this.comments = datatypeNode['rdfs:comment'] || [];

    this.langUnique = datatypeNode['sh:uniqueLang'];
    this.langHas = datatypeNode['ds:hasLanguage'] || [];
    this.langIn = datatypeNode['sh:languageIn'] || [];
    this.langDefault = datatypeNode['ds:defaultLanguage'] || [];

    this.valueHas = datatypeNode['sh:hasValue'] ? datatypeNode['sh:hasValue'].map(this.stringifyValue) : [];
    this.valueIn = datatypeNode['sh:in'] ? datatypeNode['sh:in'].map(this.stringifyValue) : [];
    this.valueDefault = datatypeNode['sh:defaultValue']
      ? this.stringifyValue(datatypeNode['sh:defaultValue'])
      : undefined;

    this.minExclusive = datatypeNode['sh:minExclusive']
      ? this.stringifyValue(datatypeNode['sh:minExclusive'])
      : undefined;
    this.maxExclusive = datatypeNode['sh:maxExclusive']
      ? this.stringifyValue(datatypeNode['sh:maxExclusive'])
      : undefined;
    this.minInclusive = datatypeNode['sh:minInclusive']
      ? this.stringifyValue(datatypeNode['sh:minInclusive'])
      : undefined;
    this.maxInclusive = datatypeNode['sh:maxInclusive']
      ? this.stringifyValue(datatypeNode['sh:maxInclusive'])
      : undefined;

    this.lengthMin = datatypeNode['sh:minLength'] ? this.stringifyValue(datatypeNode['sh:minLength']) : undefined;
    this.lengthMax = datatypeNode['sh:maxLength'] ? this.stringifyValue(datatypeNode['sh:maxLength']) : undefined;
    this.regex = datatypeNode['sh:pattern'] || [];
    this.regexFlags = datatypeNode['sh:flags'];
  }

  stringifyValue(value: any): string {
    if (isObject(value) && value['@language']) {
      return value['@value'] + '@' + value['@language'];
    }
    if (isString(value)) {
      return value;
    }
    return value.toString();
  }

  close(): void {
    this.dialogRef.close();
  }

  public t(str: string): string {
    return 'dialog-datatype.' + str;
  }
}
