import { Injectable } from '@angular/core';
import { Globals, GlobalsChanges } from '../types/globals.type';
import { Subscription } from 'rxjs';
import { DsNodeGeneric, LanguageTaggedString } from 'ds-utilities';

@Injectable()
export class UtilitiesService {
  constructor() {}

  // helper function that extracts the UID of an element, based on the given IRI of that element
  public extractUidFromIRI(iri: string): string {
    if (!iri || iri.lastIndexOf('/') === -1) {
      throw Error("Input for extractUidFromIRI() must be a string with '/' present.");
    }
    return iri.substring(iri.lastIndexOf('/') + 1);
  }

  public getMetaValue(metaValue: LanguageTaggedString[] | string, preferableLanguage: string = 'en'): string {
    if (typeof metaValue === 'string') {
      return metaValue;
    }
    if (Array.isArray(metaValue) && metaValue.length > 0) {
      const prefVal = metaValue.find((el) => el['@language'] === preferableLanguage);
      if (prefVal) {
        return prefVal['@value'];
      }
      return metaValue[0]['@value'];
    }
    return '-'; // default
  }

  // processes the URLs found in descriptions from the Vocabularies, to be rendered correctly in DSB
  public repairLinksInHTMLCode(htmlCode: string): string {
    if (!htmlCode) {
      return '';
    }
    let result = htmlCode;
    // html links (including relative links of schema.org) - e.g. <a class="xyz" href="/Text"> ...
    result = result.replace(/<a(.*?)href="(.*?)"/g, (match, otherLinkAttributes, url) => {
      if (url.startsWith('/')) {
        url = 'https://schema.org' + url;
      }
      return `<a ${otherLinkAttributes} href="${url}" target="_blank"`;
    });
    // markdown for relative links of schema.org without label - e.g. [[ImageObject]]
    result = result.replace(/\[\[(.*?)]]/g, (match, term) => {
      const url = 'https://schema.org/' + term;
      return `<a href="${url}" target="_blank">${term}</a>`;
    });
    // markdown for links (including relative schema.org) with label - e.g. [background notes](/docs/datamodel.html#identifierBg) or [WGS 84](https://en.wikipedia.org/wiki/World_Geodetic_System)
    result = result.replace(/\[(.*?)]\((.*?)\)/g, (match, label, url) => {
      if (url.startsWith('/')) {
        url = 'https://schema.org/' + url;
      }
      return `<a href="${url}" target="_blank">${label}</a>`;
    });
    // new line
    result = result.replace(/\\n/g, () => {
      return '</br>';
    });
    // markdown for bold
    result = result.replace(/__(.*?)__/g, (match, text) => {
      return `<b>${text}</b>`;
    });
    // markdown for code
    result = result.replace(/```(.*?)```/g, (match, code) => {
      return `<code>${code}</code>`;
    });
    // absolute links for schema.org - e.g. https://schema.org/gtin13 - add whitespace at start of regex to not match any cases caught before
    result = result.replace(/\shttps?:\/\/schema.org\/([^,.\s]*)/g, (match) => {
      const url = match.substring(1);
      return ` <a href="${url}" target="_blank">${url}</a>`;
    });
    return result;
  }

  // the element given is the angular component instance
  public initializeComponentWithSub<K extends keyof Globals>(element: any, globalId: K): void {
    // call render, in case that the Global was loaded BEFORE this component has been initialized
    element[globalId] = element.GlobalsService.getGlobal(globalId);
    element.render();
    // add subscription Global changes, in case that the Global is loaded AFTER this component has been initialized
    element.globalsSub = element.GlobalsService.globalsObserver.subscribe((changes: GlobalsChanges) => {
      if (Object.keys(changes).includes(globalId)) {
        if (changes[globalId]) {
          element[globalId] = changes[globalId];
          element.render();
        }
      }
    });
  }

  // unsubscribe all subscription passed, if they exist
  public unSubIfExists(subscriptions: Subscription[]): void {
    for (let s of subscriptions) {
      if (s) {
        s.unsubscribe();
      }
    }
  }

  // returns true if the given DS Node uses "advanced" constraints
  public hasAdvancedConstraints(
    node: DsNodeGeneric,
    nodeType: 'Class' | 'Property' | 'Enumeration' | 'EnumerationMember' | 'DataType',
  ): boolean {
    if (nodeType === 'Class' || nodeType === 'Enumeration' || nodeType === 'EnumerationMember') {
      return false; // there are no outstanding advanced-constraints in these node types
    }
    if (nodeType === 'Property') {
      return this.isAnyDefined([
        node['sh:equals'],
        node['sh:disjoint'],
        node['sh:lessThan'],
        node['sh:lessThanOrEquals'],
      ]);
    }
    if (nodeType === 'DataType') {
      return this.isAnyDefined([
        node['sh:minExclusive'],
        node['sh:minInclusive'],
        node['sh:maxExclusive'],
        node['sh:maxInclusive'],
        node['sh:minLength'],
        node['sh:maxLength'],
        node['sh:pattern'],
        node['sh:flags'],
        node['sh:languageIn'],
        node['ds:hasLanguage'],
        node['sh:uniqueLang'],
        node['sh:hasValue'],
      ]);
    }
    return false;
  }

  // returns true if at least one of the given values (array) is not undefined
  private isAnyDefined(val: any[]): boolean {
    return val.some((v) => v !== undefined);
  }

  // helper function that creates a corresponding explanation text (for hover title) for the given cardinality combination
  public resolveCardinalityText(min: number, max: number): { cardinalityText: string; cardinalityTitle: string } {
    if (min > 0) {
      if (max > 0) {
        if (min === max) {
          return {
            cardinalityText: min.toString(),
            cardinalityTitle: 'This property is required. It must have ' + min + ' value(s).',
          };
        } else {
          return {
            cardinalityText: min + ' - ' + max,
            cardinalityTitle: 'This property is required. It must have between ' + min + ' and ' + max + ' value(s).',
          };
        }
      } else {
        return {
          cardinalityText: min + ' +',
          cardinalityTitle: 'This property is required. It must have at least ' + min + ' value(s).',
        };
      }
    } else {
      if (max > 0) {
        return {
          cardinalityText: '0 - ' + max,
          cardinalityTitle: 'This property is optional. It must have at most  ' + max + ' value(s).',
        };
      } else {
        return {
          cardinalityText: '0 +',
          cardinalityTitle: 'This property is optional. It may have any amount of values.',
        };
      }
    }
  }
}
