import { ElementRef, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TreeProviderService } from '../tree-provider/tree-provider.service';
import { TreeLocationCoordinates } from '../tree-provider/types/tree-location-coordinates.type';
import { TreeProvider } from '../tree-provider/tree-provider.class';
import { TreeNode } from '../tree-provider/types/tree-node.type';

@Injectable()
export class TreeControlService {
  private subject = new Subject<void>();
  private tableElement: HTMLTableElement;

  constructor(private TreeProviderService: TreeProviderService) {}

  public getSubject(): Subject<void> {
    return this.subject;
  }

  public openNode(tlc: TreeLocationCoordinates, treeProvider: TreeProvider): void {
    const node = treeProvider.getNodeAtTLC(tlc);
    // change toggler status of node
    node.isOpened = true;

    // recursive helper function
    function openNodeRec(node: TreeNode) {
      node.isShown = true;
      // recursively expand all child nodes, IF this node has the toggler opened
      if (node.isOpened) {
        for (const childNode of node.children) {
          openNodeRec(childNode);
        }
      }
    }

    // change render status of children
    for (const childNode of node.children) {
      openNodeRec(childNode);
    }
    // notify subscribers
    this.subject.next();
  }

  public closeNode(tlc: TreeLocationCoordinates, treeProvider: TreeProvider): void {
    const node = treeProvider.getNodeAtTLC(tlc);
    // change toggler status of node
    node.isOpened = false;

    // recursive helper function
    function closeNodeRec(node: TreeNode) {
      node.isShown = false;
      // recursively collapse all child nodes
      for (const childNode of node.children) {
        closeNodeRec(childNode);
      }
    }

    // change render status of children
    for (const childNode of node.children) {
      closeNodeRec(childNode);
    }
    // notify subscribers
    this.subject.next();
  }

  // opens all togglers of the tree
  public async expandTree(treeProvider: TreeProvider) {
    const rootNodes = treeProvider.getTree();

    // recursive helper function
    function expandTreeRec(treeNode: TreeNode) {
      treeNode.isShown = true;
      treeNode.isOpened = true;
      for (const childNode of treeNode.children) {
        expandTreeRec(childNode);
      }
    }

    // call helper function for all children
    for (const rootNode of rootNodes) {
      expandTreeRec(rootNode);
    }
    // notify subscribers
    this.subject.next();
  }

  // opens all togglers until level 3, closes all the other
  public async collapseTree(treeProvider: TreeProvider) {
    const rootNodes = treeProvider.getTree();

    // recursive helper function
    function collapseTreeRec(treeNode: TreeNode) {
      const depth = treeNode.tlc.split('.').length; // calculate the current depth based on the TLC
      // open/show elements until the first level of ranges
      treeNode.isShown = depth < 3;
      treeNode.isOpened = depth < 2;

      for (const childNode of treeNode.children) {
        collapseTreeRec(childNode);
      }
    }

    // call helper function for all children
    for (const rootNode of rootNodes) {
      collapseTreeRec(rootNode);
    }
    // notify subscribers
    this.subject.next();
  }

  public registerTable(tableElement: HTMLTableElement): void {
    this.tableElement = tableElement;
  }

  public scrollToRow(tlc: TreeLocationCoordinates, treeProvider: TreeProvider): void {
    // get Reference node
    const refNode = treeProvider.getNodeAtTLC(tlc);
    // get Definition node
    const defNode = treeProvider.getTree().find((el) => el.dsNode['@id'] === refNode.dsNode['@id']);
    // scroll to Definition row (in table)
    const defRow = defNode.tableRow.ElementRef.nativeElement;
    this.tableElement.scrollTo({
      top: defRow.offsetTop - 58,
      behavior: 'smooth',
    }); // 58 is the height of the fixed header
    // background color animation for target row (for the user)
    setTimeout(() => {
      defNode.tableRow.isHighlighted = true;
    }, 750);
    setTimeout(() => {
      defNode.tableRow.isHighlighted = false;
    }, 2250);
  }
}
