<div class="content-wrapper">
  <div class="mat-card" id="loading" *ngIf="loading">
    <div class="loading-wrapper">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <div class="mat-card text-center" id="no-content" *ngIf="!loading && showError">
    <mat-icon id="not-found">report</mat-icon>
    <p class="text-center">
      <ng-container *ngIf="errNoContent"
        ><span [translate]="t('no-content')"></span><b>{{ listId }}</b
        ><span>!</span></ng-container
      >
      <ng-container *ngIf="!errNoContent"
        ><span>There was an error with the retrieved List</span><b>{{ listId }}:</b>
        <pre style="text-align: left; margin-top: 20px">{{ errorMessage }}</pre>
      </ng-container>
    </p>
  </div>
  <div class="mat-card" id="list-content" *ngIf="!loading && !showError">
    <div class="header-wrapper">
      <h2 class="headline margin-bottom-5">{{ listName }}</h2>
      <p class="onl-body2">{{ listDescription }}</p>
    </div>
    <div>
      <table
        class="mat-elevation-z8 bordered"
        mat-table="mat-table"
        matSort="matSort"
        matSortActive="name"
        matSortDirection="asc"
        [dataSource]="dataSource"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="name"><span translate="shared.name"></span></th>
          <td class="right-space name" mat-cell="mat-cell" *matCellDef="let element">
            <a
              class="ds-list-name primary-link font-weight-bold"
              (click)="navigateToDs(element.iri)"
              [attr.href]="element.url"
              >{{ element.name }}</a
            >
          </td>
        </ng-container>
        <ng-container matColumnDef="iri">
          <th mat-header-cell *matHeaderCellDef><span translate="shared.iri"></span></th>
          <td class="iri" mat-cell="mat-cell" *matCellDef="let element">
            <a class="iri-link" href="{{ element.iri }}" target="_blank">{{ element.iri }}</a>
          </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef><span translate="shared.description"></span></th>
          <td mat-cell="mat-cell" *matCellDef="let element">
            <p class="ds-list-description onl-body2">{{ element.description }}</p>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>
