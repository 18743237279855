import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageTaggedString, PropertyNodeV7 } from 'ds-utilities';
import { SdoAdapterService } from '../../services/sdoAdapter.service';
import { UtilitiesService } from '../../services/utilities.service';
import { DialogTermData } from '../../types/dialog-term-data.type';

@Component({
  selector: 'dialog-property',
  templateUrl: './dialog-property.component.html',
  styleUrls: ['./dialog-property.component.scss', './../../styles/term-dialog.scss'],
})
export class DialogPropertyComponent implements OnInit {
  public propertyName: string;
  public uri: string;
  public uriLink: string;
  public labels: LanguageTaggedString[];
  public comments: LanguageTaggedString[];
  public cardinalityText: string;
  public cardinalityTitle: string;
  public icon: string;

  // todo missing constraints like sh:equals, sh:disjoint, etc. must be added. Pretty sure they arent being used atm

  constructor(
    private SdoAdapterService: SdoAdapterService,
    private UtilitiesService: UtilitiesService,
    public dialogRef: MatDialogRef<DialogPropertyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogTermData,
  ) {}

  ngOnInit(): void {
    const propertyNode = this.data.dsNode as PropertyNodeV7;
    this.propertyName = this.data.dsUtilities.prettyPrintCompactedIRIs(propertyNode['sh:path']);
    this.uri = propertyNode['sh:path'];
    this.uriLink = this.SdoAdapterService.getTermReferenceUrl(propertyNode['sh:path'], this.data.sdoAdapter);
    const { cardinalityText, cardinalityTitle } = this.UtilitiesService.resolveCardinalityText(
      propertyNode['sh:minCount'],
      propertyNode['sh:maxCount'],
    );
    this.cardinalityText = cardinalityText;
    this.cardinalityTitle = cardinalityTitle;
    this.labels = propertyNode['rdfs:label'] || [];
    this.comments = propertyNode['rdfs:comment'] || [];
    this.icon = (propertyNode as any)['ext-onlim:icon'];
  }

  close(): void {
    this.dialogRef.close();
  }

  public t(str: string): string {
    return 'dialog-property.' + str;
  }
}
