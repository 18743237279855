<div class="legend-toggle margin-top-5">
  <a class="primary-link legend-toggle" (click)="iconLegendToggle()">
    <ng-container *ngIf="!iconLegendOpened"
      ><span [title]="t('toggle-hover-open') | translate">
        <mat-icon>arrow_drop_down</mat-icon><span [translate]="t('toggle-open')"></span></span
    ></ng-container>
    <ng-container *ngIf="iconLegendOpened"
      ><span [title]="t('toggle-hover-close') | translate">
        <mat-icon>arrow_drop_up</mat-icon><span [translate]="t('toggle-close')"></span></span></ng-container
  ></a>
</div>
<div class="legend" *ngIf="iconLegendOpened">
  <p>
    <button class="inline-icon-button" mat-icon-button="mat-icon-button" color="primary">
      <mat-icon>info</mat-icon></button
    ><span [translate]="t('icon-info')"></span>
  </p>
  <p>
    <button class="inline-icon-button" mat-icon-button="mat-icon-button" color="primary">
      <mat-icon>arrow_circle_down</mat-icon></button
    ><span [translate]="t('icon-inherited')"></span>
  </p>
  <p>
    <button class="inline-icon-button" mat-icon-button="mat-icon-button" color="primary">
      <mat-icon>file_open</mat-icon></button
    ><span [translate]="t('icon-int-ref')"></span>
  </p>
  <p>
    <button class="inline-icon-button" mat-icon-button="mat-icon-button" color="primary">
      <mat-icon>note_add</mat-icon></button
    ><span [translate]="t('icon-ext-ref')"></span>
  </p>
  <p>
    <button class="inline-icon-button" mat-icon-button="mat-icon-button" color="primary">
      <mat-icon>restore_page</mat-icon></button
    ><span [translate]="t('icon-root-ref')"></span>
  </p>
</div>
