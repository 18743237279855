<table class="tree-widget" #table="">
  <thead>
    <tr>
      <th
        class="tree-control"
        style="min-width: 250px; white-space: nowrap; text-align: left !important; padding-left: 5px"
      >
        <button
          class="mat-focus-indicator mat-button-sm mat-flat-button mat-button-base mat-primary"
          mat-flat-button=""
          color="primary"
          [title]="t('button-tree-collapse-hover') | translate"
        >
          <span class="mat-button-wrapper" (click)="collapseTree()"> <mat-icon>close_fullscreen</mat-icon></span>
        </button>
        <button
          class="mat-focus-indicator mat-button-sm mat-flat-button mat-button-base mat-primary"
          mat-flat-button=""
          color="primary"
          [title]="t('button-tree-expand-hover') | translate"
        >
          <span class="mat-button-wrapper" (click)="expandTree()"> <mat-icon>open_in_full</mat-icon></span></button
        ><span [translate]="t('title-tree')" style="padding-left: 5px; padding-right: 10px"></span>
      </th>
      <th class="text-center" style="padding-right: 15px !important"><span translate="shared.cardinality"></span></th>
      <th class="text-left"><span translate="shared.description"></span></th>
    </tr>
  </thead>
  <tbody>
    <tr table-row="table-row" *ngFor="let tlc of rowTLCs" [tlc]="tlc" [treeProvider]="treeProvider"></tr>
  </tbody>
</table>
