import { Component, OnInit } from '@angular/core';
import { DsV7 } from 'ds-utilities';
import { UtilitiesService } from '../../shared/services/utilities.service';
import { GlobalsService } from '../../shared/services/globals.service';

@Component({
  selector: 'tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss'],
})
export class TreeViewComponent implements OnInit {
  ds: DsV7;
  public showWidget = false;

  constructor(
    private UtilitiesService: UtilitiesService,
    private GlobalsService: GlobalsService, // used in .initializeComponentWithSub()
  ) {}

  ngOnInit(): void {
    this.UtilitiesService.initializeComponentWithSub(this, 'ds');
  }

  async render(): Promise<void> {
    if (!this.ds) {
      return;
    }
    setTimeout(() => {
      this.showWidget = true; // give the tree-widget some time to pre-render
    }, 750);
  }
}
