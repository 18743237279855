import { Injectable } from '@angular/core';
import { DsUtilitiesV7 } from 'ds-utilities';
import { DsUtil } from 'ds-utilities';
import { DsV7 } from 'ds-utilities';

@Injectable()
export class DsUtilitiesService {
  private dsUtil = DsUtil.getDsUtilitiesForDsSpecVersion('7.0');

  constructor() {}

  public getDsUtilities(): DsUtilitiesV7 {
    return this.dsUtil;
  }

  public getDsNodeAtPath(ds: DsV7, path: string, resolveReference: boolean = true): object {
    if (!path) {
      return this.dsUtil.getDsRootNode(ds);
    } else {
      return this.dsUtil.dsPathGetNode(ds, path, resolveReference);
    }
  }
}
