import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GlobalsService } from '../../../shared/services/globals.service';
import { DsUtilitiesService } from '../../../shared/services/dsUtilities.service';
import { SDOAdapter } from 'schema-org-adapter';
import { SdoAdapterService } from '../../../shared/services/sdoAdapter.service';
import { UtilitiesService } from '../../../shared/services/utilities.service';
import { PropertyRangeItem, PropertyTableItem } from '../../../shared/types/table.type';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RetrievalService } from '../../../shared/services/retrieval.service';
import { DsUtilitiesV7 } from 'ds-utilities';
import { DsV7, PropertyNodeV7, RestrictedClassNodeV7, RootNodeV7 } from 'ds-utilities';
import { DataTypeDsV7 } from 'ds-utilities';
import { NavigationService } from '../../../shared/services/navigation.service';
import { UrlBarSyncService } from '../../../shared/services/urlBarSync.service';
import { Subscription } from 'rxjs';
import { RoutingStrategy } from '../../../shared/types/globals.type';
import { DialogService } from '../../../shared/services/dialog.service';

@Component({
  selector: 'native-table',
  templateUrl: './native-table.component.html',
  styleUrls: ['./native-table.component.scss'],
})
export class NativeTableComponent implements OnInit, OnDestroy {
  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[] = ['icon', 'property', 'cardinality', 'ranges', 'description'];
  private currentNode: RestrictedClassNodeV7; // the current DS Node that should be rendered
  private dsUtilities: DsUtilitiesV7;
  private sdoAdapter: SDOAdapter;
  private ds: DsV7; // the current DS (Populated)
  private dsUnp: DsV7; // the current DS (Unpopulated) - this is needed to correctly identify used references
  public superDsIRI: string; // the IRI of the superDS of the current DS, if any
  private sort: MatSort; // the sorting component for the material table
  private globalsSub: Subscription;
  private routingStrategy: RoutingStrategy;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }

  constructor(
    private GlobalsService: GlobalsService,
    private DsUtilitiesService: DsUtilitiesService,
    private SdoAdapterService: SdoAdapterService,
    private UtilitiesService: UtilitiesService,
    private RetrievalService: RetrievalService,
    private NavigationService: NavigationService,
    private UrlBarSyncService: UrlBarSyncService,
    private DialogService: DialogService,
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.dsUtilities = this.DsUtilitiesService.getDsUtilities();
    this.routingStrategy = this.GlobalsService.getGlobal('routingStrategy');
    this.UtilitiesService.initializeComponentWithSub(this, 'ds');
  }

  ngOnDestroy(): void {
    this.UtilitiesService.unSubIfExists([this.globalsSub]);
  }

  async render(): Promise<void> {
    if (!this.ds) {
      return;
    }
    this.sdoAdapter = this.GlobalsService.getGlobal('sdoAdapter');
    this.currentNode = this.GlobalsService.getCurrentDsNode() as RestrictedClassNodeV7;
    this.superDsIRI = this.dsUtilities.getDsRootNode(this.ds)['ds:subDSOf'];
    const dsIRI = this.dsUtilities.getDsId(this.ds);
    this.dsUnp = await this.RetrievalService.getDs(dsIRI.substring(dsIRI.lastIndexOf('/') + 1), false).toPromise();
    const tableItems: PropertyTableItem[] = this.currentNode['sh:property'].map((el: any) => {
      return this.createTableItem(el);
    });
    if (tableItems.find((el) => el.icon !== undefined)) {
      //show icon column
      this.displayedColumns = ['icon'];
    } else {
      // hide icon column
      this.displayedColumns = [];
    }
    // add other columns
    this.displayedColumns.push(...['property', 'cardinality', 'ranges', 'description']);
    this.dataSource = new MatTableDataSource(tableItems);
    this.dataSource.sort = this.sort;
  }

  // creates an object holding information about a property, which is needed to render the table
  createTableItem(propertyNode: PropertyNodeV7): PropertyTableItem {
    const propertyLabel = this.dsUtilities.prettyPrintCompactedIRIs(propertyNode['sh:path']);
    const propertyRdfsLabels = propertyNode['rdfs:label'];
    const icon = (propertyNode as unknown as { 'ext-onlim:icon': string })['ext-onlim:icon'];
    const propertyURL = this.SdoAdapterService.getTermReferenceUrl(propertyNode['sh:path'], this.sdoAdapter);
    const cardinalityMin = propertyNode['sh:minCount'];
    const cardinalityMax = propertyNode['sh:maxCount'];
    const cardinalityOrder = this.calculateCardinalityOrder(cardinalityMin, cardinalityMax);
    const { cardinalityText, cardinalityTitle } = this.UtilitiesService.resolveCardinalityText(
      cardinalityMin,
      cardinalityMax,
    );
    const descriptionFromVocab = this.UtilitiesService.repairLinksInHTMLCode(
      this.sdoAdapter.getProperty(propertyNode['sh:path']).getDescription(),
    );
    const descriptionFromDs = propertyNode['rdfs:comment']?.[0]
      ? this.UtilitiesService.repairLinksInHTMLCode(propertyNode['rdfs:comment'][0]['@value'])
      : null; // todo should add language option here
    const currentPath = this.dsUtilities.dsPathAddition(
      this.GlobalsService.getGlobal('pathDs'),
      'Property',
      propertyNode['sh:path'],
    );
    const ranges: PropertyRangeItem[] = propertyNode['sh:or'].map((el: any) => {
      return this.createRangeItem(el, currentPath);
    });

    let isFromSuperDs = false;
    if (this.superDsIRI) {
      try {
        this.dsUtilities.dsPathGetNode(this.dsUnp, currentPath, true);
      } catch (e) {
        isFromSuperDs = true;
      }
    }
    const hasAdvancedConstraints = this.UtilitiesService.hasAdvancedConstraints(propertyNode, 'Property');

    return {
      propertyLabel,
      propertyRdfsLabels,
      icon,
      propertyURL,
      cardinalityMin,
      cardinalityMax,
      cardinalityOrder,
      cardinalityText,
      cardinalityTitle,
      descriptionFromVocab,
      descriptionFromDs,
      ranges,
      isFromSuperDs,
      currentPath,
      hasAdvancedConstraints,
    };
  }

  // helper function that calculates the cardinalityOrder for the given cardinality combination.
  // the cardinalityOrder is used for the ordering of properties based on their cardinality
  calculateCardinalityOrder(min: number, max: number): number {
    let cardinalityOrder = 0;
    if (min) {
      cardinalityOrder = min;
    }
    if (max) {
      cardinalityOrder += max / 1000 < 1 ? max / 1000 : 0.998;
    } else {
      cardinalityOrder += 0.999;
    }
    return cardinalityOrder;
  }

  // creates an object holding information about a property range, which is needed to render the table
  createRangeItem(rangeNode: Record<string, any>, currentPath: string): PropertyRangeItem {
    let rangeLabel: string;
    let externalURL: string;
    let viewUrl: string;
    let node = rangeNode;
    if (node['sh:node']) {
      node = node['sh:node'];
    }
    const nodeType = this.dsUtilities.dsPathIdentifyNodeType(node, this.ds);
    let referencedNode: { 'sh:class': string[] };
    let additionForPath;
    let resolvedNodeType: 'DataType' | 'Class' | 'Enumeration';

    switch (nodeType) {
      case 'DataType':
        additionForPath = node['sh:datatype'];
        rangeLabel = this.dsUtilities.getDataTypeLabel(node['sh:datatype']);
        externalURL = this.getVocabUrlForDataType(node['sh:datatype']);
        resolvedNodeType = 'DataType';
        break;
      case 'Class':
      case 'Enumeration':
        additionForPath = node['sh:class'];
        rangeLabel = this.dsUtilities.prettyPrintCompactedIRIs(node['sh:class']);
        externalURL = '';
        resolvedNodeType = nodeType;
        break;
      case 'ExternalReference':
      case 'InternalExternalReference':
      case 'InternalReference':
      case 'RootReference':
        additionForPath = node['@id'];
        referencedNode = this.dsUtilities.dsPathGetNode(
          this.ds,
          this.dsUtilities.dsPathAddition(currentPath, nodeType, additionForPath),
          true,
        ) as RootNodeV7;
        let dsGrammarNodeType = this.dsUtilities.identifyDsGrammarNodeType(referencedNode, this.ds, false);
        if (dsGrammarNodeType.includes('Class')) {
          resolvedNodeType = 'Class';
        } else {
          resolvedNodeType = 'Enumeration';
        }
        rangeLabel = this.dsUtilities.prettyPrintCompactedIRIs(referencedNode['sh:class']);
        externalURL = node['@id'];
        break;
    }
    const newPath = this.dsUtilities.dsPathAddition(currentPath, nodeType, additionForPath);
    const hasAdvancedConstraints = this.UtilitiesService.hasAdvancedConstraints(node, resolvedNodeType);

    if (this.routingStrategy) {
      viewUrl = this.UrlBarSyncService.createNewUrl(this.routingStrategy, {
        dsUID: this.GlobalsService.getGlobal('dsUID'),
        listUID: this.GlobalsService.getGlobal('listUID'),
        pathUrl: this.UrlBarSyncService.encodePathToURL(newPath),
        viewMode: undefined,
      });
    }
    return {
      nodeType,
      resolvedNodeType,
      rangeLabel,
      externalURL,
      newPath,
      viewUrl,
      hasAdvancedConstraints,
    };
  }

  // resolves a corresponding reference URL for a given XSD Datatype with help of the SDO Adapter
  public getVocabUrlForDataType(xsdDataType: DataTypeDsV7): string {
    const schemaDataType = this.dsUtilities.getSchemaDataTypeForDsDataType(xsdDataType);
    return this.sdoAdapter.getDataType(schemaDataType).getIRI();
  }

  // navigation function that changes the ds path and therefor also the current DS Node
  public changePath(newPath: string): boolean {
    this.NavigationService.navigation({ pathDs: newPath });
    this.UrlBarSyncService.setUrlChanges('new');
    return false;
  }

  // opens a dialog showing the advanced constraints of a term (ds path given)
  public openTermDialog(dsPath: string): void {
    this.DialogService.openTermDialog(this.ds, dsPath, this.dsUtilities, this.sdoAdapter);
  }

  public t(str: string): string {
    return 'native-table.' + str;
  }
}
