import { SdoAdapterService } from '../../../../shared/services/sdoAdapter.service';
import { DsV7 } from 'ds-utilities';
import { TreeProvider } from './tree-provider.class';
import { Injectable } from '@angular/core';
import { DsUtilitiesService } from '../../../../shared/services/dsUtilities.service';

@Injectable()
export class TreeProviderService {
  constructor(private SdoAdapterService: SdoAdapterService, private DsUtilitiesService: DsUtilitiesService) {}

  public create(ds: DsV7) {
    return new TreeProvider(ds, this.SdoAdapterService, this.DsUtilitiesService, 9);
  }
}
