<div class="dialog-title-container">
  <button
    class="dialog-close-button"
    mat-icon-button="mat-icon-button"
    (click)="close()"
    [title]="'shared.close' | translate"
  >
    <mat-icon>close</mat-icon>
  </button>
  <div style="display: flex">
    <div class="onl-icon" *ngIf="icon" style="padding-top: 15px; padding-left: 10px">
      <mat-icon class="margin-0 color-primary">{{ icon }}</mat-icon>
    </div>
    <div>
      <h1 mat-dialog-title="mat-dialog-title">{{ propertyName }}</h1>
      <p class="mat-dialog-title-sub">Property</p>
    </div>
  </div>
</div>
<div mat-dialog-content="mat-dialog-content">
  <table class="details-table">
    <tr>
      <th [title]="t('iri-hover') | translate">{{ t('iri') | translate }}:</th>
      <td>
        {{ uri
        }}<a class="margin-left-5" target="_blank" [href]="uriLink" [title]="t('hover-iri-icon') | translate">
          <button class="inline-icon-button" mat-icon-button="mat-icon-button" color="primary">
            <mat-icon>launch</mat-icon>
          </button></a
        >
      </td>
    </tr>
    <tr>
      <th [title]="t('cardinality-hover') | translate">{{ 'shared.cardinality' | translate }}:</th>
      <td [title]="cardinalityTitle">{{ cardinalityText }}</td>
    </tr>
    <tr>
      <th [title]="t('labels-hover') | translate">{{ t('labels') | translate }}:</th>
      <!--({{labels.length}})-->
      <td>
        <ul *ngIf="labels.length > 0">
          <li *ngFor="let l of labels">
            <span class="property-rdfs-lang">{{ l['@language'] }} -</span
            ><span class="property-rdfs-value margin-left-5">{{ l['@value'] }}</span>
          </li>
        </ul>
        <span *ngIf="labels.length === 0">-</span>
      </td>
    </tr>
    <tr>
      <th [title]="t('comments-hover') | translate">{{ t('comments') | translate }}:</th>
      <!--({{comments.length}})-->
      <td>
        <ul *ngIf="comments.length > 0">
          <li *ngFor="let c of comments">
            <span class="property-rdfs-lang">{{ c['@language'] }} -</span
            ><span class="property-rdfs-value margin-left-5">{{ c['@value'] }}</span>
          </li>
        </ul>
        <span *ngIf="comments.length === 0">-</span>
      </td>
    </tr>
  </table>
</div>
<div mat-dialog-actions="mat-dialog-actions">
  <button class="mat-button onl-button" (click)="close()" mat-flat-button="mat-flat-button" color="primary">
    {{ 'shared.close' | translate }}
  </button>
</div>
