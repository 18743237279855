import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable, OnDestroy } from '@angular/core';

// https://stackoverflow.com/questions/52763285/add-matdialog-popup-to-angular-root-and-not-to-body

export class OnlOverlayContainer extends OverlayContainer implements OnDestroy {
  private onlOverlayContainerClass: string = 'onl-overlay-container' as const;

  private routerOutlet(): Element {
    // this may cause issues if we have multiple dsb widgets in the page - which should not happen in practice
    return document.querySelector('dsb-widget').shadowRoot.querySelector('router-outlet');
  }

  public getContainerElement(): HTMLElement {
    const element: Element = this.routerOutlet().querySelector('.' + this.onlOverlayContainerClass);

    if (!element) {
      this._createContainer();
    }

    return this._containerElement;
  }

  protected _createContainer(): void {
    const container: HTMLDivElement = document.createElement('div');
    container.classList.add(this.onlOverlayContainerClass);

    const element: Element = this.routerOutlet();
    if (element !== null) {
      element.appendChild(container);
      this._containerElement = container;
    }
  }
}
